<template>
    <div>
        <div class="searchBox">
            <slot name="tableSearch"></slot>
        </div>
        <div class="tableTips">
            <slot name="tableTips"></slot>
        </div>
        <el-table
            @selection-change="handleSelectionChange"
            :data="tabltData"
            style="width: 100%"
            :header-cell-style="{background: '#f5f5f5'}"
        >
            <slot name="column"></slot>
        </el-table>
        <div class="pagination">
            <el-pagination
                background
                layout="prev, pager, next"
                :total="pagination.total_count"
                @current-change="changePage"
            >
            </el-pagination>
        </div>
    </div>
</template>

<script>
    export default {
        props: ['tabltData','pagination'],
        data() {
            return {
                note: ''
            }
        },
        methods: {
            changePage(value){
                if(value) {
                    this.$emit('pageChange',value)
                }
            },
            handleSelectionChange(val){
                this.$emit('selectData', val)
            }
        },
    }
</script>

<style lang="scss" scoped>
    .searchBox{
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-wrap: wrap;
    }
    .pagination{
        display: flex;
        justify-content: flex-end;
        margin-top: 30px;
        padding-bottom: 40px;
    }
    .tableTips{
        margin-bottom: 10px;
        font-size: 15px;
    }
</style>