<template>
    <div>
      <VTable :tabltData="tableData" :pagination="pagination" @pageChange="pageChange" v-loading="loading">
          <template slot="tableSearch">
              <el-form :model="searchForm" ref="searchForm" label-width="100px" class="demo-ruleForm" :inline="true">
                  <el-form-item>
                    <el-button type='primary' size='small' @click="handlAdd"><i class="el-icon-plus"></i>  添加</el-button>
                  </el-form-item>
                  <el-form-item label="" prop="search_keyword">
                      <el-input type="text" size='small' v-model="searchForm.search_keyword"></el-input>
                  </el-form-item>              
                  <el-form-item>
                      <el-button type="primary" size='small' @click="submiteSearch('searchForm')">查询</el-button>
                  </el-form-item>
              </el-form>
          </template>
          <template slot='column'>
              <!-- <el-table-column type="index" label="序号"></el-table-column> -->
              <el-table-column prop="mobile_no" label="手机号"></el-table-column>
              <el-table-column prop="user_name" label="姓名"></el-table-column>
              <el-table-column prop="date" label="绑定微信">
                  <template slot-scope="scope">
                      <div class="bindStatus" v-if="scope.row.avatar">
                          <img class="bindWxImg" :src="scope.row.avatar" alt="">
                          <span>{{scope.row.nick_name}}</span>
                      </div>
                      <div v-else style="color: #f13e40">未绑定</div>
                  </template>
              </el-table-column>
              <el-table-column prop="role_name" label="角色"></el-table-column>
              <el-table-column prop="status" label="状态">
                  <template slot-scope="scope">
                      <div>{{scope.row.status == 1? '启用中': '禁用中'}}</div>
                  </template>
              </el-table-column>
              <el-table-column prop="updated_time" label="创建时间">
                <template slot-scope="scope">
                    {{ handleTime(scope.row.created_time) }}
                </template>
              </el-table-column>
              <el-table-column prop="name" label="操作">
                  <template slot-scope="scope">
                    <el-button v-show="scope.row.status == 1" type="text" @click="pauseUser(scope.row)" size="small">禁用</el-button>
                    <el-button v-show="scope.row.status == 10" type="text" @click="unPauseUser(scope.row)" size="small">启用</el-button>
                    <el-button type="text" size="small" @click="handlAdd(scope.row)">编辑</el-button>
                    <el-button type="text" style="color: #f13e40" @click="deleteUser(scope.row)" size="small">删除</el-button>
                  </template>
              </el-table-column>
          </template>
      </VTable>
      <el-dialog
          :title="user_id? '编辑用户': '添加用户'"
          :visible.sync="dialogVisible"
          width="30%"
          :close-on-click-modal="false"
          :show-close="false"
      >
          <template>
             <el-form :model="editorForm" :rules="editorRules" ref="editorForm" label-width="100px" class="demo-ruleForm">
                  <el-form-item label="手机号" prop="mobile_no">
                      <el-input type="text" size='small' v-model="editorForm.mobile_no"></el-input>
                  </el-form-item>
                  <el-form-item label="登录密码" prop="password">
                      <el-input type="text" size='small' v-model="editorForm.password"></el-input>
                  </el-form-item>
                  <el-form-item label="姓名" prop="user_name">
                      <el-input type="text" size='small' v-model="editorForm.user_name"></el-input>
                  </el-form-item>
                  <el-form-item label="所属角色" prop="role_id">
                      <el-select v-model="editorForm.role_id" placeholder="请选择所属角色">
                          <el-option v-for="(item, index) in roleList" :key="index" :label="item.role_name" :value="item.role_id"></el-option>
                      </el-select>
                  </el-form-item>
                  <el-form-item label="状态" prop="status">
                      <el-radio-group v-model="editorForm.status">
                          <el-radio :label="1">启用</el-radio>
                          <el-radio :label="10">禁用</el-radio>
                      </el-radio-group>
                  </el-form-item>            
                  <el-form-item class="dialogFooter">
                      <!-- <el-button type="primary" size='small' @click="submiteEditor('editorForm')">查询</el-button> -->
                        <el-button @click="resetForm('editorForm')">取 消</el-button>
                        <el-button type="primary" @click="submiteEditor('editorForm')">确 定</el-button>
                  </el-form-item>
              </el-form> 
          </template>
      </el-dialog>
    </div>
</template>

<script>
  import { timestampToTime } from '../utils/timeset'
  import VTable from '../components/form/VTable.vue'
  import { encryPwd} from '../utils/encryPwd'
  import * as Manage from '../api/manage'
  import * as Role from '../api/role'
  export default {
    data() {
      return {
        loading: true,
        user_id: '',
        searchForm: {
          search_keyword: '',
        },
        current_page: 1,
        editorForm: {
          mobile_no: '',
          password: '',
          user_name: '',
          role_id: '',
          status: ''
        },
        dialogVisible: false,
        tableData: [],
        roleList: [],
        editorRules: {
          mobile_no: [{required: true, message: '请输入手机号', trigger: 'blur' }],
          password: [{required: true, message: '请输入密码', trigger: 'blur' }],
          user_name: [{required: true, message: '请输入姓名', trigger: 'blur' }],
          role_id: [{required: true, message: '请选择所属角色', trigger: 'blur' }],
          status: [{required: true, message: '请选择状态', trigger: 'blur' }],
        },
        pagination: {}
      }
    },
    components: {
        VTable
    },
    created() {
      this.getList()
    },
    methods: {
        //获取管理员列表
        getList(params) {
          let obj = {}
          if(params) {
            obj = Object.assign(params,{current_page: this.current_page,page_size: 10},this.searchForm)
          } else {
            obj = {
              ...this.searchForm,
              current_page: this.current_page,
              page_size: 10
            }
          }
          Manage.Manager(obj).then( res => {
            if( res && res.list ) {
              this.tableData = res.list
              this.pagination = res.pagination
              this.loading = false
            }
          })
        },
        //添加操作
        handlAdd(row) {
          this.user_id = row.user_id? row.user_id: ''
          this.dialogVisible = true
          if( row.user_id ) {
            Manage.ManagerDetail(row.user_id).then(res => {
              this.editorForm = res
            })
          }
          this.getRoleList()
        },
        getRoleList() {
          Role.Role({current_page:1, page_size: 999}).then( res => {
            if( res.list && res.list ) {
              this.roleList = res.list
            }
          })
        },
        //提交搜索表单
        submiteSearch(formName) {
          this.$refs[formName].validate((valid) => {
            if ( valid ) {
              this.getList()
            }
          })
        },
        //提交编辑表单
        submiteEditor(formName) {
          this.loading = true
          this.$set(this.editorForm, 'password', encryPwd(this.editorForm.password))
          this.$refs[formName].validate((valid) => {
            if ( valid ) {
              if(!this.user_id) {
                Manage.AddManager(this.editorForm).then( res => {
                  this.getList()
                })
              } else {
                Manage.eidtManager(this.user_id, this.editorForm).then(res => {
                  this.getList()
                })
              }
              this.resetForm('editorForm')
            }
          })
        },
        //重置表单
        resetForm(formName) {
          this.$refs[formName].resetFields();
          this.dialogVisible = false
        },
        //删除
        deleteUser(row) {
          this.loading = true
          let user_id = row.user_id
          Manage.deleteManager(user_id).then(res => {
            this.loading = false
            this.getList()
          })
        },
        //禁用
        pauseUser(row) {
          this.loading = true
          let user_id = row.user_id
          Manage.pauseManager({user_id,status: 10}).then(res => {
            this.loading = false
            this.getList()
          })
        },
        //启用
        unPauseUser(row){
          this.loading = true
          let user_id = row.user_id
          Manage.pauseManager({user_id,status: 1}).then(res => {
            this.loading = false
            this.getList()
          })
        },
        pageChange(value) {
            if(value) {
              this.current_page = value
            }
            this.getList()
        },
        //时间处理
        handleTime(time) {
          return timestampToTime(time)
        },
    },
  }
</script>

<style lang="scss" scoped>
  .dialogFooter{
    text-align: right;
  }
  .bindStatus{
    display: flex;
    align-items: center;
    .bindWxImg{
      border-radius: 50%;
      width: 30px;
      height: 30px;
      margin-right: 10px;
    }
  }

</style>