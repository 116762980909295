import request from '../utils/request'

//银行列表列表
export function BankqcList (params) {
    return request({
        url: '/v1/bq/bank-qrcodes',
        method: 'get',
        params
    })
}

//新增银行列表
export function AddBankqcList (params) {
    return request({
        url: '/v1/bq/bank-qrcodes',
        method: 'post',
        data: params
    })
}

//详情
export function BankqcListDetail (id) {
    return request({
        url: `/v1/bq/bank-qrcodes/${id}`,
        method: 'get'
    })
}

//更新
export function eidtBankqcList (id,params) {
    return request({
        url: `/v1/bq/bank-qrcodes/${id}`,
        method: 'put',
        data: params
    })
}

//删除
export function deleteBankqcList (id) {
    return request({
        url: `/v1/bq/bank-qrcodes/${id}`,
        method: 'delete'
    })
}

//银行列表列表
export function GetParentBank (params) {
    return request({
        url: '/v1/bq/bank-branches/root',
        method: 'get',
        params
    })
}

//天报表
export function reportTable (params) {
    return request({
        url: '/v1/bq/bank-qrcode-daily-reports',
        method: 'get',
        params
    })
}


// 下载银行码
export function downBankCode (params) {
    return request({
        url: '/v1/bq/bank-qrcodes/download',
        method: 'get',
        params
    })
  }