<template>
    <div>
        <div class="header">
            <div class="left">
               <div class="items">
                   <div class="lable">码名称</div>
                   <div class="content">{{detail.qrcode_name || ''}}</div>
               </div>
               <div class="items">
                   <div class="lable">银行信息</div>
                   <div class="content">{{getBanName(detail.bank_id)}}</div>
               </div>
               <div class="items">
                   <div class="lable">银行</div>
                   <div class="content">{{detail.root_bank_name || ''}}</div>
               </div>
               <div class="items" v-show="detail.channel_type == 2">
                   <div class="lable">员工编号</div>
                   <div class="content">{{detail.staff_code || ''}}</div>
               </div>
               <div class="items">
                   <div class="lable">渠道类型</div>
                   <div class="content">{{detail.channel_type == 1 ? '银行': (detail.channel_type == 2? '员工':'商户')}}</div>
               </div>
               <div class="items" v-show="detail.channel_type == 2">
                   <div class="lable">员工姓名</div>
                   <div class="content">{{detail.staff_name || ''}}</div>
               </div>
               <div class="items">
                   <div class="lable">银行码编号</div>
                   <div class="content">{{detail.scene || ''}}</div>
               </div>
               <div class="items" v-show="detail.channel_type == 3">
                   <div class="lable">商户名称</div>
                   <div class="content">{{detail.merchant_name || ''}}</div>
               </div>
            </div>
            <div class="right">
                <div class="totalNum">{{detail.bind_total}}</div>
                <div class="title">总绑卡数量</div>
            </div>
        </div>
        <!-- 图表 -->
        <div class="body">
            <div class="selectdate">
                <!-- <el-select v-model="dateType" @change="changeChart">
                    <el-option
                    v-for="item in dateTypeoptions"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                    </el-option>
                </el-select> -->
                <el-date-picker
                    v-model="dateArea"
                    type="daterange"
                    @change="changeChart"
                    range-separator="至"
                    start-placeholder="开始日期"
                    :picker-options="option"
                    value-format="timestamp"
                    end-placeholder="结束日期">
                </el-date-picker>
            </div>
            <div class="chart" id="chart" ref="mychart"></div>
        </div>
        <!-- 列表 -->
        <div class="tableBox">
            <el-table
                :data="tableData"
                :header-cell-style="{background: '#f5f5f5'}"
            >
                <el-table-column align="center" prop="date" label="日期"></el-table-column>
                <el-table-column align="center" prop="success_count" label="绑卡成功数量"></el-table-column>
            </el-table>
        </div>
        <el-button class="btn" type='primary' style="margin-top: 12px;" @click="$router.go(-1)">返回</el-button>
    </div>
</template>

<script>
import VTable from '../../components/form/VTable.vue'
import * as Banklist from '../../api/bankList'
import * as BankCodeSet from '../../api/bankCodeSet'
  import * as Bank from '../../api/bank'
    export default {
        data() {
            return {
                qrcode_id: '',
                begin_time: '', //默认一周前
                end_time: '',//当前日期
                detail: {},
                bankList: [],
                dateTypeoptions: [{
                value: 1,
                label: '近7天'
                }, {
                value: 2,
                label: '近15天'
                }, {
                value: 3,
                label: '近30天'
                }],
                dateType: 1, //默认的日期选择为7天  1:7天  2:15天  3:30天
                dateArea: [],
                option:{
                    disabledDate:(time)=> {
                        return time.getTime() > Date.now() || time.getTime() < Date.now()-31 * 24 * 3600 * 1000
                    }
                },
                tableData: []
            }
        },
        created() {
            let id = this.$route.query.id
            this.qrcode_id = id
            this.getLocalTime()
            this.getReportTable()
            this.bqcodeDetail(id)
            this.getBankList()
        },
        mounted() {
            this.initChart([],[])
        },
        component: {
            VTable,
        },
        methods: {
            //码详情
            bqcodeDetail(id) {
                BankCodeSet.bankCodeDetail(id).then(res => {
                    this.detail = res
                })
            },
            changeChart(){
                console.log(this.dateArea)
                this.begin_time = this.dateArea[0]/1000,
                this.end_time = this.dateArea[1]/1000
                this.getReportTable()
            },
            getReportTable() {
                let obj = {
                    qrcode_id: this.qrcode_id,
                    begin_time: this.begin_time,
                    end_time: this.end_time
                }
                Banklist.reportTable(obj).then(res => {
                    let timeList = []
                    let timeP = []
                    let dataList = []
                    if(res.list) {
                        if(res.list.length) {
                            res.list.map(item => {
                                timeList.push(item.date)
                                dataList.push(item.success_count)
                                item.date = this.handleTime(item.date.toString(),true)
                            })
                        }
                    }
                    this.tableData = res.list
                    if(timeList.length) {
                        timeList.map(item => {
                            timeP.push(this.handleTime(item.toString()))
                        })
                    }
                    this.initChart(timeP,dataList)
                })
            },
            handleTime(time, isTable) {
                // time = `${time}`
                let resuTime = time.slice(4,8)
                // if( isTable ) {
                    resuTime = time.slice(0,4) + '/' + resuTime.slice(0,2) + '/' + resuTime.slice(2,4)
                // }
                // resuTime = resuTime.slice(0,2) + '-' + resuTime.slice(2,4)
                return resuTime
            },
            getLocalTime() {
                this.begin_time =
                    new Date(
                        new Date(new Date().toLocaleDateString()).getTime() -7 * 24 * 3600 * 1000
                    ).getTime() / 1000
                this.end_time = new Date(new Date(new Date().toLocaleDateString()).getTime()+ 24 * 3600 * 1000).getTime() / 1000
            },
            initChart(timeP,dataList) {
                let dom = this.$refs.mychart;
                let myChart = this.$echarts.init(dom)

                // 指定图表的配置项和数据
                var option = {
                    tooltip: {
                        trigger: 'axis'
                    },
                    legend: {
                        orient: 'vertical',
                        left: 'center',
                        bottom:'bottom',
                        data: ['绑卡成功数量']
                    },
                    xAxis: {
                        type: 'category',
                        data: timeP
                    },
                    yAxis: {
                        type: 'value',
                        axisLine: {
                            show: true
                        }
                    },
                    series: [{
                        name: '绑卡成功数量',
                        data: dataList,
                        type: 'line',
                        lineStyle: {
                            color: '#00c520'
                        }
                    }]
                };

                // 使用刚指定的配置项和数据显示图表。
                myChart.setOption(option);
                setTimeout(function(){
                    window.onresize = function() {
                        myChart.resize()
                    }
                },200)
            },
            //选择银行
            getBankList(){
                Bank.Bank({current_page: 1,page_size: 999}).then(res => {
                    if(res.list) {
                        this.bankList = res.list
                    }
                })
            },
            getBanName(id) {
            // debugger
                let name = ''
                if(this.bankList.length) {
                    this.bankList.map(item => {
                    if(item.bank_id == id) {
                        name = item.bank_name
                    }
                    })
                }
                return name
            }
        },
    }
</script>

<style lang="scss" scoped>
    .header{
        display: inline-block;
        width: 90vw;
        height: 150px;
        background: #ffe297;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .left{
            box-sizing: border-box;
            width: 70vw;
            height: 100%;
            padding-left: 15px;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            flex-wrap: wrap;
            .items{
                width: 28vw;
                display: flex;
                justify-content: flex-start;
                align-items: center;
                flex-wrap: wrap;
                // background: rgb(51, 209, 59);
                font-size: 13px;
                .lable{
                    width: 90px;
                    text-align: left;
                    font-weight: bold;
                    font-size: 14px;
                }
                .content{
                    width: 250px;
                    text-align: left;
                }
            }
        }
        .right{
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            width: 25%;
            box-sizing: border-box;
            height: 80px;
            // background: rgb(56, 172, 166);
            border-left: 1px solid #000;
            .totalNum{
                font-size: 38px;
                margin-bottom: 10px;
            }
            .title{
                font-size: 13px;
            }
        }
    }
    .body{
        width: auto;
        .selectdate{
            margin-top: 20px;
            width: 100px;
        }
        .chart{
            width: 90%;
            height: 400px;
            margin-bottom: 50px;
        }
    }
    .tableBox{
        margin: 10px auto;
        width: 90%;
    }
    .btn{
        margin-left: 50%;
        transform: translate(-50%);
    }
</style>