<template>
    <div>
      <VTable :tabltData="tableData" :pagination="pagination" @pageChange="pageChange">
          <template slot="tableSearch">
              <el-form label-width="100px" class="demo-ruleForm" :inline="true">
                  <el-form-item>
                    <el-button v-role="'BindExp'" type='primary' size='small' @click="handleExport(false)"><i class="el-icon-plus"></i>  导入</el-button>
                  </el-form-item>
              </el-form>
          </template>
          <template slot='column'>
              <!-- <el-table-column type="index" label="序号"></el-table-column> -->
              <el-table-column prop="file_name" label="导入文件名称"></el-table-column>
              <el-table-column prop="total_count" label="导入数量"></el-table-column>
              <el-table-column prop="success_count" label="成功数量"></el-table-column>
              <el-table-column prop="user_name" label="导入人员"></el-table-column>
              <el-table-column prop="imported_time" label="导入日期">
                  <template slot-scope="scope">
                        {{ handleTime(scope.row.imported_time) }}
                  </template>
              </el-table-column>
              <el-table-column prop="address" label="状态">
                  <template slot-scope="scope">
                      <div v-if="scope.row.success_count == 0">全部失败</div>
                      <div v-if="scope.row.success_count != 0 && scope.row.success_count != scope.row.total_count">部分失败</div>
                      <div v-if="scope.row.success_count == scope.row.total_count">全部成功</div>
                  </template>
              </el-table-column>
              <el-table-column prop="name" label="操作">
                  <template slot-scope="scope">
                    <el-button type="text" size="small" @click="upFiledData(scope.row.task_id)">下载失败数据</el-button>
                  </template>
              </el-table-column>
          </template>
      </VTable>
      <el-dialog
          :title="hasResult? '导入结果': '导入数据'"
          :visible.sync="dialogVisible"
          width="30%"
          :close-on-click-modal="false"
          :show-close="false"
      >
            <template v-if="!hasResult">
              <div class="upBox">
                <div>
                  <el-upload
                      class="upload-demo"
                      action="//"
                      :auto-upload="false"
                      :on-change="fileChange"
                      multiple
                      :limit="1"
                      :file-list="fileList">
                          <div class="unload">
                              <el-button size="small" type="primary">点击上传</el-button>
                              <div v-show="!file" class="noFile">未选择任何文件</div>
                          </div>
                  </el-upload>
                </div>
                <div class="downloadTemp"><a href="https://cdn.wecloudx.com/bindTemplate.xlsx">下载导入模板</a></div>
              </div>
            </template>
            <template v-if="hasResult">
                <div class="loaded">
                    <div class="filed">共{{upResult.fail + upResult.success}}条数据，成功导入{{upResult.success}}条，导入失败{{upResult.fail}}条</div>
                    <div class="downloadTemp" @click="confirmUpFiledData">下载导入失败数据</div>
                </div>
            </template>
            <span slot="footer" class="dialog-footer" v-if="!hasResult">
                <el-button @click="dialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="uploadFile">确 定</el-button>
            </span>
            <span slot="footer" class="dialog-footer" v-if="hasResult">
                <el-button @click="dialogVisible = false">关闭</el-button>
            </span>
      </el-dialog>
    </div>
</template>

<script>
  import * as Bind from '../api/bind'
  import VTable from '../components/form/VTable.vue'
  import { timestampToTime } from '../utils/timeset'

  export default {
    data() {
      return {
        id: '',
        dialogVisible: false,
        hasResult: false,
        fileList:[],
        tableData: [],
        current_page: 1,
        formData: new FormData(),
        upResult: {},
        pagination: {},
        file:''
      }
    },
    components: {
        VTable
    },
    created() {
      this.loading = true
      this.getList()
    },
    methods: {
        //获取菜单列表
        getList() {
          Bind.BindList({current_page: this.current_page,page_size: 10}).then( res => {
            if( res.list && res.list ) {
              this.tableData = res.list
              this.pagination = res.pagination
              this.loading = false
            }
          })
        },
        //添加操作
        handleExport(hasResult,res) {
          this.hasResult = hasResult
          this.upResult = res
          if(!hasResult) {
              this.dialogVisible = true
          }
        },
        pageChange(value) {
            if(value) {
              this.current_page = value
            }
            this.getList()
        },
        handleRemove(file, fileList) {
            console.log(file, fileList);
        },
        fileChange(file, fileList) {
            this.file = file
        },
        //上传导入文件
        uploadFile() {
          let formData = new FormData()
          if(this.file) {
            formData.append('file', this.file.raw)
            Bind.AddBind(formData).then(res => {
              this.handleExport(true,res)
            })
          }
        },
        //时间处理
        handleTime(time) {
          return timestampToTime(time)
        },
        confirmUpFiledData() {
          let id = this.upResult.id
          this.upFiledData(id)
        },
        //下载导入失败数据
        upFiledData(id) {
          Bind.BindDetail({task_id: id}).then(res => {
            if(res.file_hash) {
              Bind.Bindfaile({file_hash: res.file_hash})
              this.dialogVisible = false
            }
          })
        }
    },
  }
</script>

<style lang="scss" scoped>
@import '../styles/vars.scss';
  .dialogFooter{
    text-align: right;
  }
  .bindStatus{
    display: flex;
    align-items: center;
    .bindWxImg{
      border-radius: 50%;
      width: 30px;
      height: 30px;
      margin-right: 10px;
    }
  }
  .upBox{
    display: flex;
    align-items: center;
  }
  .unload{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    .noFile{
        color: #999;
        margin: 0 10px;
    }
    .downloadTemp{
        color: #42b983;
    }
  }
  .loaded{
    .filed{
        color: #999;
        margin-bottom: 20px;
    }
    .downloadTemp{
        color: #42b983;
    }
  }
  a{
    color: #42b983;
    text-decoration: none;
  }
</style>