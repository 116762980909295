import request from '../utils/request'

//银行列表
export function Bank (params) {
    return request({
        url: '/v1/bq/bank-branches',
        method: 'get',
        params
    })
}

//新增银行
export function AddBank (params) {
    return request({
        url: '/v1/bq/bank-branches',
        method: 'post',
        data: params
    })
}

//批量导入
export function batchImport(params) {
    return request({
        url: '/v1/bq/bank-branches/import',
        method: 'post',
        data: params
    })
}

//详情
export function BankDetail (id) {
    return request({
        url: `/v1/bq/bank-branches/${id}`,
        method: 'get'
    })
}

//更新
export function eidtBank (id,params) {
    return request({
        url: `/v1/bq/bank-branches/${id}`,
        method: 'put',
        data: params
    })
}

//删除
export function deleteBank (id) {
    return request({
        url: `/v1/bq/bank-branches/${id}`,
        method: 'delete'
    })
}


