<template>
    <div>
      <VTable :tabltData="tableData" :pagination="pagination" @pageChange="pageChange" v-loading="loading">
          <template slot="tableSearch">
              <el-form :model="searchForm" ref="searchForm" label-width="100px" class="demo-ruleForm" :inline="true">
                  <el-form-item>
                    <el-button type='primary' size='small' @click="handlAdd"><i class="el-icon-plus"></i>  添加</el-button>
                  </el-form-item>
                  <el-form-item label="" prop="search_keyword">
                      <el-input type="text" size='small' v-model="searchForm.search_keyword" :clearable="true"></el-input>
                  </el-form-item>              
                  <el-form-item>
                      <el-button type="primary" size='small' @click="submiteSearch('searchForm')">查询</el-button>
                  </el-form-item>
              </el-form>
          </template>
          <template slot='column'>
              <!-- <el-table-column type="index" label="序号"></el-table-column> -->
              <el-table-column prop="role_name" label="角色名称"></el-table-column>
              <el-table-column prop="description" label="角色描述"></el-table-column>
              <el-table-column prop="created_time" label="创建时间">
                <template slot-scope="scope">
                    {{ handleTime(scope.row.created_time) }}
                </template>
              </el-table-column>
              <el-table-column prop="name" label="操作">
                  <template slot-scope="scope">
                    <el-button type="text" size="small" @click="handlAdd(scope.row)">编辑</el-button>
                    <el-button type="text" style="color: #f13e40" size="small" @click="handelDelete(scope.row)">删除</el-button>
                  </template>
              </el-table-column>
          </template>
      </VTable>
    </div>
</template>

<script>
  import { timestampToTime } from '../utils/timeset'
  import VTable from '../components/form/VTable.vue'
  import * as Role from '../api/role'
  export default {
    data() {
      return {
        loading: true,
        role_id: '',
        searchForm: {
          search_keyword: '',
        },
        current_page: 1,
        dialogVisible: false,
        tableData: [],
        pagination: {}
      }
    },
    components: {
        VTable
    },
    created() {
      this.getList()
    },
    methods: {
        //获取角色列表
        getList(params) {
          let obj = {}
          if(params) {
            obj = Object.assign(params,{current_page: this.current_page,page_size: 10},this.searchForm)
          } else {
            obj = {
              ...this.searchForm,
              current_page: this.current_page,
              page_size: 10
            }
          }
          Role.Role(obj).then( res => {
            if( res.list && res.list ) {
              this.tableData = res.list
              this.pagination = res.pagination
              this.loading = false
            }
          })
        },
        //添加操作
        handlAdd(row) {
          this.role_id = row.role_id? row.role_id: ''
          this.$router.push({path: '/RoleDetail',query: {id: this.role_id}})
        },
        //删除
        handelDelete(row) {
          this.loading = true
          let role_id = row.role_id
          Role.deleteRole(role_id).then(res => {
            this.loading = false
            this.getList()
          })
        },
        //提交搜索表单
        submiteSearch(formName) {
          this.$refs[formName].validate((valid) => {
            if ( valid ) {
              this.getList()
            }
          })
        },
        pageChange(value) {
            if(value) {
              this.current_page = value
            }
            this.getList()
        },
        //时间处理
        handleTime(time) {
          return timestampToTime(time)
        },
    },
  }
</script>

<style lang="scss" scoped>
  .dialogFooter{
    text-align: right;
  }
  .bindStatus{
    display: flex;
    align-items: center;
    .bindWxImg{
      border-radius: 50%;
      width: 30px;
      height: 30px;
      margin-right: 10px;
    }
  }

</style>