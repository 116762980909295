/*
 * @Description: 
 * @version: v1.0.0
 * @Author: ladd
 * @Date: 2021-08-27 09:32:29
 * @LastEditors: ladd
 * @LastEditTime: 2021-09-02 15:55:14
 */
import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import About from '../views/About.vue'
import Manager from '../views/mamager.vue'
import BindData from '../views/BindData.vue'
import Role from '../views/Role.vue'
import Menu from '../views/Menu.vue'
import Bank from '../views/Bank.vue'
import Template from '../views/Template.vue'
import RoleDetail from '../views/RoleDetail.vue'
import ReportDetial from '../views/bankCodeList/reportDetial.vue'
import TemplateDetail from '../views/templateDetail.vue'
import RouterView from '../components/blackpage/blackpage.vue'
import * as Permission from '../api/authority'

import { Message } from 'element-ui'

Vue.use(VueRouter)

const routes = [
  {
    path: '/Home',
    name: 'Home',
    component: Home,
    children: [
      { path:'/bankCodeSet',
        component: () => import('@/views/bankCodeSet/bankCodeSet'),
        name: '银行码配置',
        routKey: 'BankCodeSet'      //立即配置：CodeSet   批量导入：CodeExp
      },
      { path:'/bankCodeList', 
        component: () => import('@/views/bankCodeList/bankCodeList'), 
        name: '银行码列表',
        routKey: 'BankCodeList'
      },
      { path:'/BankCodeDetail', 
        component: () => import('@/views/bankCodeList/bankCodeDetail'), 
        name: '银行码详情',
        hidden: true
      },
      { path:'/ReportDetial', component: ReportDetial, name: '报表详情', hidden: true},
      {
        path:'/baseSet',
        name: '基础配置',
        component: RouterView,
        routKey: 'BaseSet',
        children: [
          { path:'/Bank', component: Bank, name: '银行管理',routKey: 'Bank'},     //添加：BankAdd 编辑：BankEdi 删除：BankDel  列表：BankList
          { path:'/Template', component: Template, name: '样式模板',routKey: 'Template'},     //添加：TempAdd 编辑：TempEdi 删除：TempDel  列表：TempList
          { path:'/BindData', component: BindData, name: '数据导入',routKey: 'BindData'},    //导入： BindExp 列表：BindList
          { path:'/Manager', component: Manager, name: '用户管理',routKey: 'Manager'},
          { path:'/Role', component: Role, name: '角色管理',routKey: 'Role'},
          { path:'/Menu', component: Menu, name: '菜单管理',routKey: 'Menu'},
          { path:'/RoleDetail', component: RoleDetail, name: '角色详情', hidden: true},
          { path:'/TemplateDetail', component: TemplateDetail, name: '模板详情', hidden: true},
        ]
      }
    ]
  },
  {
    path:'/',
    name: 'login',
    hidden: true,
    component: ()=> import('@/views/login/login')
  },
  {
    path: '/about',
    name: '关于',
    hidden: true,
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to,from,next)=>{
  if(to.path == '/'){
    localStorage.removeItem('permissions')
    next();
  }else{
    if(!localStorage.getItem('token')) {
      Message.error('请登录！')
      localStorage.removeItem('permissions')
      next('/');
    } else {
      next();
    }
  }
})

export default router
