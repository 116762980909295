/*
 * @Description: 域名配置
 * @version: v1.0.0
 * @Author: ladd
 * @Date: 2021-09-26 11:45:50
 * @LastEditors: ladd
 * @LastEditTime: 2021-09-26 14:21:24
 */
//基础配置


let istest = 1
let config = {
  host: '',
  token: '',
  appid: 'wxeb6dd7cb97c78f97',  //扫码配置的appid
  url: 'https://qrcode.wecloudx.com/',//扫码后的回调地址
}

if (process.env.NODE_ENV === 'development') {
  config.host = '/api';
} else {
  if(istest) { //测试
    config.host = 'http://test.qrcode.wecloudx.com/api';
  } else {
    config.host = '//qrcode.wecloudx.com/api';
  }
}

export default config