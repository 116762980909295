<template>
    <div>
      <VTable :tabltData="tableData" :pagination="pagination" @pageChange="pageChange" v-loading="loading">
          <template slot="tableSearch">
              <el-form label-width="100px" class="demo-ruleForm" :inline="true">
                  <el-form-item>
                    <el-button type='primary' size='small' @click="handlAdd"><i class="el-icon-plus"></i>  添加</el-button>
                  </el-form-item>
              </el-form>
          </template>
          <template slot='column'>
              <!-- <el-table-column type="index" label="序号"></el-table-column> -->
              <el-table-column prop="permission_type" label="类型">
                <template slot-scope="scope">
                    {{scope.row.permission_type == 1 ? '页面权限' : '操作权限'}}
                </template>
              </el-table-column>
              <el-table-column prop="parent_name" label="上级菜单"></el-table-column>
              <el-table-column prop="permission_name" label="名称"></el-table-column>
              <el-table-column prop="created_time" label="创建时间">
                <template slot-scope="scope">
                    {{ handleTime(scope.row.created_time) }}
                </template>
              </el-table-column>
              <el-table-column prop="name" label="操作">
                  <template slot-scope="scope">
                    <el-button type="text" size="small" @click="handlAdd(scope.row)">编辑</el-button>
                    <el-button type="text" style="color: #f13e40" size="small">删除</el-button>
                  </template>
              </el-table-column>
          </template>
      </VTable>
      <el-dialog
          :title="permission_id? '编辑': '添加'"
          :visible.sync="dialogVisible"
          width="40%"
          :close-on-click-modal="false"
          :show-close="false"
      >
          <template>
             <el-form :model="editorForm" :rules="editorRules" ref="editorForm" label-width="100px" class="demo-ruleForm">
                  <el-form-item label="操作" prop="permission_type">
                      <el-radio-group v-model="editorForm.permission_type">
                          <el-radio :label="1">菜单</el-radio>
                          <el-radio :label="2">操作功能</el-radio>
                      </el-radio-group>
                  </el-form-item>            
                  <el-form-item label="上级菜单" prop="parent_id">
                      <el-select v-model="editorForm.parent_id" placeholder="请选择活动区域">
                          <el-option v-for="(item, index) in parentMunList" :key="index" :label="item.permission_name" :value="item.permission_id"></el-option>

                          <!-- <el-option label="区域一" value="shanghai"></el-option>
                          <el-option label="区域二" value="beijing"></el-option> -->
                      </el-select>
                  </el-form-item>
                  <el-form-item label="菜单名称" prop="permission_name">
                      <el-input type="text" size='small' v-model="editorForm.permission_name"></el-input>
                  </el-form-item>
                  <el-form-item label="标识" prop="permission_key">
                      <el-input type="text" size='small' v-model="editorForm.permission_key"></el-input>
                  </el-form-item>
                  <el-form-item label="路径" prop="intercept_path">
                      <el-input type="text" size='small' v-model="editorForm.intercept_path"></el-input>
                  </el-form-item>
                  <el-form-item class="dialogFooter">
                      <!-- <el-button type="primary" size='small' @click="submiteEditor('editorForm')">查询</el-button> -->
                        <el-button @click="resetForm('editorForm')">取 消</el-button>
                        <el-button type="primary" @click="submiteEditor('editorForm')">确 定</el-button>
                  </el-form-item>
              </el-form> 
          </template>
      </el-dialog>
    </div>
</template>

<script>
  import * as Authority from '../api/authority'
  import VTable from '../components/form/VTable.vue'
  import { timestampToTime } from '../utils/timeset'
  export default {
    data() {
      return {
        loading: true,
        permission_id: '',
        searchForm: {
          search_keyword: '',
        },
        editorForm: {
          permission_type: '',
          parent_id: '',
          permission_name: '',
          permission_key: '',
          intercept_path: ''
        },
        current_page: 1,
        dialogVisible: false,
        tableData: [],
        parentMunList: [],  //父级菜单列表
        editorRules: {
          permission_type: [{required: true, message: '请选择操作类型', trigger: 'blur' }],
          permission_name: [{required: true, message: '请输入菜单名称', trigger: 'blur' }],
          permission_key: [{required: true, message: '请输入权限标识', trigger: 'blur' }],
          intercept_path: [{required: true, message: '请输入菜单路径', trigger: 'blur' }],
        },
        pagination: {}
      }
    },
    components: {
        VTable
    },
    created() {
      this.getList()
    },
    methods: {
        //获取菜单列表
        getList() {
          Authority.permissions({current_page: this.current_page,page_size: 10}).then( res => {
            if( res.list && res.list ) {
              this.tableData = res.list
              this.pagination = res.pagination
              this.loading = false
            }
          })
        },
        //添加操作
        handlAdd(row) {
          this.permission_id = row.permission_id? row.permission_id: ''
          this.dialogVisible = true
          if( row.permission_id ) {
            Authority.PermissionsDetail(row.permission_id).then(res => {
              this.editorForm = res
            })
          }
          this.parentMun()
        },
        //提交编辑表单
        submiteEditor(formName) {
          this.loading = true
          this.$refs[formName].validate((valid) => {
            if ( valid ) {
              if(!this.permission_id) {
                Authority.AddPermissions(this.editorForm).then( res => {
                  this.getList()
                })
              } else {
                Authority.eidtPermissions(this.permission_id, this.editorForm).then(res => {
                  this.getList()
                })
              }
              this.resetForm('editorForm')
            } else {
            }
          })
        },
        //重置表单
        resetForm(formName) {
          this.$refs[formName].resetFields();
          this.dialogVisible = false
        },
        //时间处理
        handleTime(time) {
          return timestampToTime(time)
        },
        pageChange(value) {
            if(value) {
              this.current_page = value
            }
            this.getList()
        },
        //父级菜单选项
        parentMun() {
          Authority.permissions({current_page: 1,page_size: 999}).then(res => {
            if( res.list && res.list ) {
              let list = res.list
              let parentMun = []
              list.map(item => {
                if( item.permission_type == 1 ) {
                  parentMun.push({permission_name: item.permission_name, permission_id: item.permission_id})
                }
              })
              this.parentMunList = parentMun
            }
          })
        }
    },
  }
</script>

<style lang="scss" scoped>
  .dialogFooter{
    text-align: right;
  }
  .bindStatus{
    display: flex;
    align-items: center;
    .bindWxImg{
      border-radius: 50%;
      width: 30px;
      height: 30px;
      margin-right: 10px;
    }
  }

</style>