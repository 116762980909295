import request from '../utils/request'

//角色列表
export function Role (params) {
    return request({
        url: '/v1/bq/roles',
        method: 'get',
        params
    })
}

//新增角色
export function AddRole (params) {
    return request({
        url: '/v1/bq/roles',
        method: 'post',
        data: params
    })
}

//详情
export function RoleDetail (id) {
    return request({
        url: `/v1/bq/roles/${id}`,
        method: 'get'
    })
}

//更新
export function eidtRole (id,params) {
    return request({
        url: `/v1/bq/roles/${id}`,
        method: 'put',
        data: params
    })
}

//删除
export function deleteRole (id) {
    return request({
        url: `/v1/bq/roles/${id}`,
        method: 'delete'
    })
}