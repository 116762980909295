/*
 * @Description: 
 * @version: v1.0.0
 * @Author: ladd
 * @Date: 2021-08-27 09:32:29
 * @LastEditors: ladd
 * @LastEditTime: 2021-08-27 09:57:27
 */
import request from '../utils/request'

//菜单列表
export function permissions (params) {
    return request({
        url: '/v1/bq/permissions',
        method: 'get',
        params
    })
}

//新增菜单
export function AddPermissions (params) {
    return request({
        url: '/v1/bq/permissions',
        method: 'post',
        data: params
    })
}

//详情
export function PermissionsDetail (id) {
    return request({
        url: `/v1/bq/permissions/${id}`,
        method: 'get'
    })
}

//更新
export function eidtPermissions (id,params) {
    return request({
        url: `/v1/bq/permissions/${id}`,
        method: 'put',
        data: params
    })
}

//权限树
export function PermissionsTree () {
    return request({
        url: `/v1/bq/permissions/tree`,
        method: 'get'
    })
}

//用户权限列表
export function permissionList () {
    return request({
        url: '/v1/bq/permissions/light',
        method: 'get'
    })
}