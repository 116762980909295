<template>
  <div class="about">
    <h1 @click="handlAdd">This is an about page</h1>
  </div>
</template>
<script>
export default {
  data() {
    return {
      
    }
  },
  methods: {
    handlAdd(row) {
            // console.log('llllllllllllllllllllllllllll',this.$router);
          this.id = row.id? row.id: ''
        //   this.$roter.push({path: '/bankCodeList',query: {id: this.id}})
        },
  },
}
</script>
