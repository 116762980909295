/*
 * @Description: 银行码api
 * @version: v1.0.0
 * @Author: ladd
 * @Date: 2021-08-31 09:40:03
 * @LastEditors: ladd
 * @LastEditTime: 2021-09-03 15:03:33
 */
import request from '../utils/request'

//银行列表
export function bankList (params) {
  return request({
      url: '/v1/bq/bank-branches',
      method: 'get',
      params
  })
}

// 模板列表
export function templateList (params) {
  return request({
      url: '/v1/bq/bank-qrcode-templates',
      method: 'get',
      params
  })
}

// 创建银行码
export function bankQrcodes (params) {
  return request({
      url: '/v1/bq/bank-qrcodes',
      method: 'post',
      data: params
  })
}
// 批量导入
export function importFile (params) {
  return request({
      url: '/v1/bq/bank-qrcodes/import',
      method: 'post',
      data: params
  })
}

// 下载失败记录
export function downFile (params) {
  return request({
      url: '/v1/bq/bank-qrcodes/fail',
      method: 'get',
      params
  })
}

// 银行码详情
export function bankCodeDetail (id) {
  return request({
      url: '/v1/bq/bank-qrcodes/' + id,
      method: 'get'
  })
}

// 场景值
export function genScene (params) {
  return request({
      url: '/v1/bq/bank-qrcodes/gen-scene',
      method: 'post',
      data: params
  })
}
