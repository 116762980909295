import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui'
import * as echarts from 'echarts'
import 'element-ui/lib/theme-chalk/index.css'
import './styles/element-variables.scss'

import './directive/roleDirective'

Vue.use(ElementUI)
Vue.config.productionTip = false

Vue.prototype.$echarts = echarts
// 全局注册http转https
Vue.filter('to-https', function (value) {
  let path = value.split(':')[1]
  return location.protocol + path;
})
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
