import Vue from 'vue'
import * as Permission from '../api/authority'
// const list = localStorage.getItem('permissions')
// const permissionList = list.split(',')



// console.log('kkkkkkkkkkkkkk', permissionList);
// const permissionList = ['qbCodeMsg','CodeExp','CodeSet','BankCodeList','BankCodeSet','Manager','add_role','Role','Menu','BaseSet']
const roleDirective = Vue.directive('role', {
    // bind: (el, binding, vnode ) => {
    //     Permission.permissionList({current_page:1,page_size: 999}).then(res => {
    //         // const permissionList = ['BankCodeSet','BaseSet','Manager','Role','Menu',
    //         // 'CodeSet', 'CodeExp','BankAdd','BankEdi','BankDel','TempAdd','TempEdi','TempDel','BindExp']
    //         let permissionList = []
    //         if(res.list) {
    //             res.list.forEach(item => {
    //                 permissionList.push(item.key)
    //             });  
    //         }
    //         const hasPermission = ( userPermission ) => {
    //             let userPermissionList = Array.isArray(userPermission) ? userPermission : userPermission
    //             if(permissionList && permissionList.indexOf(userPermissionList) > -1) {
    //                 return permissionList.indexOf(userPermissionList) > -1 ? true : false
    //             } else {
    //                 return false
    //             }
    //         }
    //         if(!hasPermission(binding.value)) {
    //             // el.style.display = 'none'
    //             el.parentNode.removeChild(el)
    //         }
    //     })
    // },
    bind: (el, binding, vnode ) => {
        const list = localStorage.getItem('permissions')
        const permissionList = list.split(',')
        const hasPermission = ( userPermission ) => {
            let userPermissionList = Array.isArray(userPermission) ? userPermission : userPermission
            let isIncloud = permissionList.some(item => item === userPermissionList)
            return isIncloud
        }
        if(!hasPermission(binding.value)) {
            el.style.display = 'none'
            // el.parentNode.removeChild(el)
        }
    },
    inserted: (el, binding, vnode ) => {
        const list = localStorage.getItem('permissions')
        const permissionList = list.split(',')
        const hasPermission = ( userPermission ) => {
            let userPermissionList = Array.isArray(userPermission) ? userPermission : userPermission
            let isIncloud = permissionList.some(item => item === userPermissionList)
            return isIncloud
        }
        if(!hasPermission(binding.value)) {
            // el.style.display = 'none'
            el.parentNode.removeChild(el)
        }
    },
    update: (el, binding, vnode ) => {
        const list = localStorage.getItem('permissions')
        const permissionList = list.split(',')
        const hasPermission = ( userPermission ) => {
            let userPermissionList = Array.isArray(userPermission) ? userPermission : userPermission
            let isIncloud = permissionList.some(item => item === userPermissionList)
            return isIncloud
        }
        if(!hasPermission(binding.value)) {
            el.style.display = 'none'
            // el.parentNode.removeChild(el)
        }
    },
})

export { roleDirective }