/*
 * @Description: 
 * @version: v1.0.0
 * @Author: ladd
 * @Date: 2021-09-01 09:24:05
 * @LastEditors: ladd
 * @LastEditTime: 2021-09-03 15:19:01
 */
import request from '../utils/request'

//公共接口


//银行、行政
export function dictionaries (params) {
    return request({
        url: '/v1/bq/dictionaries/get-descendants',
        method: 'post',
        data: params
    })
}

//上传素材
export function uploadFiles (params) {
    return request({
        url: '/v1/bq/bank-qrcode-assets/upload',
        method: 'post',
        data: params
    })
}

//下载数据
export function downloadData (params) {
    return request({
        url: `/v1/bq/resources`,
        method: 'get',
        params,
        isDownload: true,
    })
}

//用户所属银行列表(权限)
export function ownerBank (params) {
    return request({
        url: '/v1/bq/bank-branches/owner',
        method: 'get',
        params
    })
}