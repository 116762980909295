const CryptoJS = require("crypto-js");
// import md5 from './../assets/js/md5.min';
const md5 = require('md5');
const sha1 = require('sha1');
const SHA256Key = 'f3fe80fdaf280965b795bacc19a911cd24579ad8642be81bb56c5fb7c823d5c0'

export function rebuildUrl(method, url, q,isDownload) {
  let ps = [],
    resQ = {},
    resUrl = url

  let nonce = parseInt(Math.random() * 1E10) + ''
  let timestamp = parseInt((new Date()).valueOf() / 1000)

  let psForSign = {
    nonce,
    timestamp,
    // _sign: false
  }
  let psForUrl = {
    nonce,
    timestamp,
    // _sign: false
  }

  if (url.indexOf('?') !== -1) {
    //获取参数
    let tmp = url.split('?')
    resUrl = tmp[0]
    tmp[1] && tmp[1].split('&').map(str => {
      if (!str) return
      let couple = str.split('=')
      //去掉 参数中的 sign
      if (couple[0] === 'sign') return
      if (couple[0]) {
        psForUrl[couple[0]] = couple[1]
        psForSign[couple[0]] = couple[1]
      }
    })
  }
  if (q && method.toLowerCase() === 'get') {
    let tq

    Object.keys(q).map(v => {
      if (v) {
        psForUrl[v] = encodeURIComponent(q[v] || '')
        psForSign[v] = encodeURIComponent(q[v] || '')
      }
    })
    tq = JSON.stringify(q)
    psForUrl.q = tq
    psForSign.q = tq
  } else if (q) { //???
    let tq = JSON.stringify(q)
    psForSign.q = tq
    psForUrl.q = tq
  }

  // 构建Sign
  Object.keys(psForUrl).map(key => {
    if (key === 'access_token') {
      token = psForUrl[key]
    }
    ps.push(`${key}=${encodeURIComponent(psForUrl[key] || '')}`)
    resQ[key] = psForUrl[key]
  })

  let psSign = [];
  Object.keys(psForSign).sort((a, b) => a.localeCompare(b)).map(key => {
    psSign.push(`${key}=${psForSign[key] || ''}`)
  })
  let strA = md5(psSign.join('&')) + nonce + timestamp

  let hash = encodeURIComponent(CryptoJS.HmacSHA256(strA, SHA256Key).toString());
  resQ.sign = hash

  // 构建 URL
  if(isDownload) {
    resUrl += '?' + ps.join('&') + `&sign=${hash}`
  }

  return {
    fullUrl: resUrl,
    q: resQ
  }
}