<template>
    <div class="container">
        <h4 class="title">{{template_id? '编辑银行码样式模板' : '添加银行码样式模板'}}</h4>
        <template v-if="active === 0">
            <el-col :span='24' class="header">
                <el-steps :active="active" finish-status="success" :align-center="true">
                    <el-step title="选择主题模板"></el-step>
                    <el-step title="自定义模板样式"></el-step>
                </el-steps>
            </el-col>
            <el-form :model="editorForm" ref="editorForm" :rules="editorRules" label-width="200px" class="demo-ruleForm" :inline="false">
                <el-form-item label="请选择主题模板" prop="template">
                            <template>
                            </template>
                </el-form-item>
                <el-form-item label="">
                        <div class="templateListBox" >
                            <template v-for="(item, index) in templateList">
                                <div :key="index" class="templateItemBox">
                                    <img class="templateItem" src="https://cdn.wecloudx.com/bank_template.jpg"/>
                                    <el-radio v-model="checkIdx" :label="index"><div></div></el-radio>
                                </div>
                            </template>
                        </div>
                </el-form-item>              
            </el-form>
        </template>
        <template v-if="active === 1">
            <el-col :span="15" style="marginTop: 70px;marginRight: 30px">
                <section v-show="active === 1">
                    <el-form :model="bankCodeData" ref="bankCodeData" :rules="providerRules" label-width="200px" class="form-list">
                        <el-form-item label="选择银行" prop="bank_id">
                            <el-select v-model="bankCodeData.bank_id" filterable clearable  placeholder="请选择银行，支持输入搜索" class="_el-select" size="small">
                                <el-option v-for="item in ownerBankList" v-show="item.bank_level === 1" :key="item.bank_id" :label="item.bank_name"
                                    :value="item.bank_id">
                                </el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="名称" prop="template_name">
                            <el-input v-model="bankCodeData.template_name" style="width: 250px"></el-input> 
                        </el-form-item>
                        <el-form-item label="银行logo" prop="logo">
                            <el-upload
                                class="upload-demo"
                                action="//"
                                :auto-upload="false"
                                :on-change="banklogoChange"
                                list-type="picture"
                                multiple
                                :limit="1"
                                :file-list="fileList">
                                    <div class="unload">
                                        <el-button size="small" type="primary">点击上传</el-button>
                                        <div class="noFile">请上传jpg/png格式图片，尺寸：</div>
                                    </div>
                            </el-upload>
                        </el-form-item>
                        <el-form-item label="二维码logo" prop="qrcode_logo">
                            <el-upload
                                class="upload-demo"
                                action="//"
                                :auto-upload="false"
                                :on-change="qcChange"
                                list-type="picture"
                                multiple
                                :limit="1"
                                :file-list="fileList">
                                    <div class="unload">
                                        <el-button size="small" type="primary">点击上传</el-button>
                                        <div class="noFile">请上传jpg/png格式图片，尺寸：</div>
                                    </div>
                            </el-upload>
                        </el-form-item>
                        <el-form-item label="默认卡片背景色" prop="card_bg_color">
                            <div style="display:flex">
                                <el-color-picker v-model="bankCodeData.card_bg_color"></el-color-picker> 
                                <div style="marginLeft:10px">{{bankCodeData.card_bg_color}}</div>
                            </div>
                        </el-form-item>
                        <el-form-item label="默认主标题字体颜色" prop="title_font_color">
                            <div style="display:flex">
                                <el-color-picker v-model="bankCodeData.title_font_color"></el-color-picker>
                                <div style="marginLeft:10px">{{bankCodeData.title_font_color}}</div> 
                            </div>
                        </el-form-item>
                        
                        <el-form-item label="默认描述字体颜色" prop="desc_font_color">
                            <div style="display:flex">
                                <el-color-picker v-model="bankCodeData.desc_font_color"></el-color-picker>
                                <div style="marginLeft:10px">{{bankCodeData.desc_font_color}}</div>
                            </div>
                        </el-form-item>
                        <el-form-item label="默认码编号字体颜色" prop="`no_font_color">
                            <div style="display:flex">
                                <el-color-picker v-model="bankCodeData.no_font_color"></el-color-picker>
                                <div style="marginLeft:10px">{{bankCodeData.no_font_color}}</div>
                            </div>
                        </el-form-item>
                        <el-form-item class="dialogFooter" style="width: 60vw;marginTop: 200px;textAlign:center">
                            <el-button @click="active--">上一步</el-button>
                            <el-button type="primary" @click="submiteEditor('bankCodeData')">确 定</el-button>
                        </el-form-item>
                    </el-form>
                </section>
            </el-col>
            <el-col :span="5">
                <div class="preview" v-if="active === 1">
                    <div class="preWrap">
                        <h5 class="preTitle">银行码图片预览</h5>
                        <div class="preContent">
                            <div class="preHeader">
                            <el-image
                                :src="imgUrl.bankLogo"
                                fit="contain" class="bankLogo"></el-image>
                                <el-image
                                :src="imgUrl.wxLogo"
                                fit="contain" class="wxLogo"></el-image>
                            </div>
                            <div class="preInfo" :style="{backgroundColor: bankCodeData.card_bg_color || '#13b32f'}">
                            <div>
                                <h6 class="preInfo_t" :style="{color: bankCodeData.title_font_color || '#fff' }">{{bankCodeData.title || '微信支付首绑送微信立减金'}}</h6>
                                <pre class="preInfo_d" :style="{color: bankCodeData.desc_font_color || '#fff' }">{{bankCodeData.desc || '首次绑卡'}}</pre>
                            </div>
                            <div class="qrcode_warp" :style="{backgroundImage: 'url('+imgUrl.qrLogo+')'}"></div>
                            <div class="code" :style="{color: bankCodeData.no_font_color || '#fff' }">123456789123456789</div>
                            </div>
                        </div>
                    </div>
                    <!-- <div class="previewBtn">点击预览</div> -->
                </div>
            </el-col>
        </template>
        <el-button v-show="active === 0" class="btn" type='primary' style="margin-top: 12px;" @click="next">{{btnText}}</el-button>
        <el-button v-show="active === 0" class="btn" style="margin-top: 12px;" @click="$router.go(-1)">返回</el-button>
    </div>
</template>

<script>
    import * as Public from '../api/public'
    import * as Bank from '../api/bank'
    import * as Template from '../api/template'
    export default {
        data() {
            return {
                imgUrl: {
                    bankLogo: require('@/assets/images/u1412_03.png'),
                    wxLogo: require('@/assets/images/u1412_06.png'),
                    qrLogo: require('@/assets/images/u1412_11.png')
                },
                active: 0,
                editorForm: {
                    tp: ''
                },
                editorRules: {
                    template: [{required: true, trigger: 'blur' }],
                },
                templateList: [{}],
                checkIdx: 0,
                bankList: [],
                bankCodeData: {
                    logo: '',
                    qrcode_logo: '',
                    bank_id: '',
                    template_name: '',
                    card_bg_color: '#13b32f',
                    title_font_color: '#ffffff',
                    desc_font_color: '#ffffff',
                    no_font_color:'#ffffff'
                },
                fileList: [],
                providerRules: {
                    bank_id: [{required: true, message: '请选择银行', trigger: 'blur' }],
                    template_name: [{required: true, message: '请输入模板名称', trigger: 'blur' }],
                    logo: [{required: true, message: '请上传银行logo', trigger: 'blur' }],
                    qrcode_logo: [{required: true, message: '请上传二维码logo', trigger: 'blur' }],
                    card_bg_color: [{required: true, message: '请选择默认卡片背景色', trigger: 'blur' }],
                    title_font_color: [{required: true, message: '请选择默认主标题字体颜色', trigger: 'blur' }],
                    desc_font_color: [{required: true, message: '请选择默认描述字体颜色', trigger: 'blur' }],
                    no_font_color: [{required: true, message: '请选择默认码编号字体颜色', trigger: 'blur' }],
                },
                btnText: '下一步',
                template_id: '',
                ownerBankList: [],
            }
        },
        created() {
            let query = this.$route.query
            this.template_id = query.id
            if(query.id) {
                this.getDetail(query.id)
            }
            // this.getBankList()
            this.getownerBankList()
        },
        methods: {
            getownerBankList() {
                Public.ownerBank({current_page: 1,page_size: 999}).then(res => {
                    if(res.list) {
                        this.ownerBankList = res.list
                    }
                })
            },
            getBankList(){
                Bank.Bank({bank_level: 1}).then(res => {
                    if(res.list) {
                        this.bankList = res.list
                    }
                })
            },
            getDetail(id){
                Template.TemplateDetail(id).then(res => {
                    let obj = {
                        ...res,
                        ...res.template_attr_struct,
                    }
                    this.bankCodeData = obj
                    this.$set(this.imgUrl, 'bankLogo',obj.logo)
                })
            },
            next() {
                if (this.active++ > 2) this.active = 0;
            },
            banklogoChange(file, fileList) {
                this.uploadFile(file, 'logo')
            },
            qcChange(file, fileList) {
                this.uploadFile(file, 'qrcode_logo')
            },
            //上传素材
            uploadFile(file,type) {
                let formData = new FormData()
                if(file) {
                    formData.append('file', file.raw)
                    Public.uploadFiles(formData).then(res => {
                        if(res.url) {
                            if(type == 'logo') {
                                this.$set(this.bankCodeData,'logo',res.url)
                                this.$set(this.imgUrl,'bankLogo',res.url)
                            } else {
                                this.$set(this.bankCodeData,'qrcode_logo',res.url)
                            }
                        } else {
                            console.log('上传失败');
                        }
                    })
                }
            },
            //提交表单
            submiteEditor(formName) {
                this.$refs[formName].validate((valid) => {
                    if(valid) {
                        let obj = {
                            bank_id: this.bankCodeData.bank_id,
                            template_style: 1, //模板id  默认为1
                            template_name: this.bankCodeData.template_name,
                            template_attr_struct: {
                                logo: this.bankCodeData.logo,
                                qrcode_logo: this.bankCodeData.qrcode_logo,
                                card_bg_color: this.bankCodeData.card_bg_color,
                                title_font_color: this.bankCodeData.title_font_color,
                                desc_font_color: this.bankCodeData.desc_font_color,
                                no_font_color: this.bankCodeData.no_font_color
                            },
                        }
                        if(this.template_id) {
                            Template.eidtTemplate(this.template_id, obj).then(res => {
                                this.$router.go(-1)
                            })
                        } else {
                            Template.AddTemplate(obj).then(res => {
                                this.$router.go(-1)
                            })
                        }
                    }
                })
            }
        },
    }
</script>

<style lang="scss" scoped>
    $pre-width: 1024px;
    $pre-heght: 1448px;
    $width: 313px;
    $heght: 442px;
    .container{
        width: 100%;
        .header{
           margin-top: 30px;
        }
    }
    .templateListBox{
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        flex-wrap: wrap;
        margin-left: -120px;
        width: 60vw;
        height: 100%;
        background: #f2f2f2;
        padding: 30px 50px;
        .templateItemBox{
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            margin-bottom: 20px;
            .templateItem{
                width: 200px;
                height: 300px;
                margin-bottom: 20px;
            }
        }
    }
    .btn{
        margin-left: 50%;
        transform: translate(-50%);
    }
    .unload {
        display: flex;
        justify-content: flex-start;
        .noFile{
            margin-left: 10px;
            font-size: 10px;
        }
    }
    .preview{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    .preWrap{
      width: 400px;
      height: 586px;
      background: rgba(247, 247, 247, 1);
      display: flex;
      flex-direction: column;
      align-items: center;
      .preTitle{
        text-align: center;
        line-height: 48px;
      }
      .preContent{
        width: $width;
        height: $heght;
        background: #fff;
        box-sizing: border-box;
       
      }
      .preHeader{
        width: 100%;
        height: 207px / $pre-heght * $heght;
        display: flex;
        align-items: center;
        justify-content: center;
        .bankLogo{
          width: 477px / $pre-width * $width;
          height: 66px / $pre-heght * $heght;
          margin-right: 40px / $pre-width * $width;
        }
        .wxLogo{
          width: 312px / $pre-width * $width;
          height: 60px / $pre-heght * $heght;
        }
      }
      .preInfo{
        width: 100%;
        height: $heght - (207px / $pre-heght * $heght);
        padding: 94px / $pre-heght * $heght 0 40px / $pre-heght * $heght;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        .preInfo_t{
          text-align: center;
          font-size: 20px;
          line-height: 1;

        }
        .preInfo_d{
          margin-top: 32px / $pre-heght * $heght;
          margin-bottom: 70px / $pre-heght * $heght;
          text-align: center;
          font-size: 14px;
          line-height: 18px;
          font-family: Avenir, Helvetica, Arial, sans-serif;
        }
        .qrcode_warp{
          width: 590px / $pre-width * $width;
          height: 770px / $pre-heght * $heght;
          background-repeat: no-repeat;
          background-size: contain;
        }
      }
    }
    .previewBtn{
      margin: 20px;
      color: #22ac38;
      cursor: pointer;
    }
    .colorBox{
        display: flex;
        // justify-content: flex-start;
        // align-items: center;
    }
    .dialogFooter{
        margin-top: 300px;
        width: 100%;
        text-align: center;
    }
  }
</style>