<template>
    <div v-loading="loading">
        <div class="titl">银行分行管理</div>
        <!-- 页面 -->
        <el-row :gutter="20">
            <!-- 总行 -->
            <el-col :span="6"><div class="grid-content bg-purple">
                <div class="titleBox">
                    <div class="title">银行总行</div>
                    <el-button v-role="'BankAdd'" type="text" @click="addBank('headBank')"><i class="el-icon-plus"></i>  添加</el-button>
                </div>
                <div class="banList">
                    <template v-for="(item, index) in headBankList">
                        <div :class="['bankBox',headBankCheckedId == index ? 'checked': '']" :key="index" @click="chekedBank('headBank',index,item)">
                            <div class="bankTitle">{{item.bank_name}}</div>
                            <div class="bankBtn" v-if="headBankCheckedId == index">
                                <el-link v-role="'BankDel'" class="btn" :underline="false" type="danger" @click="handelDelete(1,item)">删除</el-link>
                            </div>
                        </div>
                    </template>
                </div>
            </div></el-col>
            <!-- 省分行 -->
            <el-col :span="6"><div class="grid-content bg-purple">
                <div class="titleBox">
                    <div class="title">省分行</div>
                    <el-button v-role="'BankAdd'" type="text" @click="addBank('proviceBank',null,false)"><i class="el-icon-plus"></i>  添加</el-button>
                </div>
                <div class="banList">
                    <template v-for="(item, index) in proviceBankList">
                        <div :class="['bankBox',proviceBankCheckedId == index ? 'checked': '']" :key="index" @click="chekedBank('proviceBank',index,item)">
                            <div class="bankTitle">{{item.bank_name}}</div>
                            <div class="bankBtn" v-if="proviceBankCheckedId == index">
                                <el-link v-role="'BankEdi'" class="btn" :underline="false" type="success" @click="addBank('proviceBank',item,true)">编辑</el-link>
                                <el-link v-role="'BankDel'" class="btn" :underline="false" type="danger" @click="handelDelete(2,item)">删除</el-link>
                            </div>
                        </div>
                    </template>
                </div>
            </div></el-col>
            <!-- 市分行 -->
            <el-col :span="6"><div class="grid-content bg-purple">
                <div class="titleBox">
                    <div class="title">市分行</div>
                    <el-button v-role="'BankAdd'" type="text" @click="addBank('cityBank',null,false)"><i class="el-icon-plus"></i>  添加</el-button>
                </div>
                <div class="banList">
                    <template v-for="(item, index) in cityBankList">
                        <div :class="['bankBox',cityBankCheckedId == index ? 'checked': '']" :key="index" @click="chekedBank('cityBank',index,item)">
                            <div class="bankTitle">{{item.bank_name}}</div>
                            <div class="bankBtn" v-if="cityBankCheckedId == index">
                                <el-link v-role="'BankEdi'" class="btn" :underline="false" type="success" @click="addBank('cityBank',item,true)">编辑</el-link>
                                <el-link v-role="'BankDel'" class="btn" :underline="false" type="danger" @click="handelDelete(3,item)">删除</el-link>
                            </div>
                        </div>
                    </template>
                </div>
            </div></el-col>
            <!-- 支行 -->
            <el-col :span="6"><div class="grid-content bg-purple">
                <div class="titleBox">
                    <div class="title">支行</div>
                    <el-button v-role="'BankAdd'" type="text" @click="addBank('areaBank',null,false)"><i class="el-icon-plus"></i>  添加</el-button>
                </div>
                <div class="banList">
                    <template v-for="(item, index) in areaBankList">
                        <div :class="['bankBox',areaBankCheckedId == index ? 'checked': '']" :key="index" @click="chekedBank('areaBank',index,item)">
                            <div class="bankTitle">{{item.bank_name}}</div>
                            <div class="bankBtn" v-if="areaBankCheckedId == index">
                                <el-link v-role="'BankEdi'" class="btn" :underline="false" type="success" @click="addBank('areaBank',item,true)">编辑</el-link>
                                <el-link v-role="'BankDel'" class="btn" :underline="false" type="danger" @click="handelDelete(4,item)">删除</el-link>
                            </div>
                        </div>
                    </template>
                </div>
            </div></el-col>
        </el-row>
        <!-- 表单 -->
        <el-dialog
          :title="dialogTitle"
          :visible.sync="dialogVisible"
          width="40%"
          :close-on-click-modal="false"
          :show-close="false"
        >
            <template>
                <el-form :model="editorForm" ref="editorForm" :rules="editorRules" label-width="150px" class="demo-ruleForm">
                    <el-form-item v-if="bankType == 'headBank'" label="选择银行" prop="headParent_id">
                        <el-select v-model="editorForm.headParent_id" filterable  placeholder="请选择银行" @change="checkHeadbankChange">
                            <el-option v-for="(item, index) in allBanks" :key="index" :label="item.name" :value="item.id"></el-option>
                        </el-select>
                        <div class="headBankList">
                            <el-tag
                                size="mini"
                                v-for="tag in checkedHeadBanks"
                                :key="tag.name"
                                closable
                                @close="handleClose(tag)"
                                type="success">
                                {{tag.name}}
                            </el-tag>
                        </div>
                    </el-form-item>
                    <!-- 所属银行 -->
                     <el-form-item label="所属银行"  v-if="bankType == 'proviceBank' || bankType == 'areaBank' || bankType == 'cityBank'" prop="proviParent_id">
                        <el-select v-model="editorForm.proviParent_id" value-key="bank_id" filterable placeholder="请选择所属银行" @change="checkBank">
                            <el-option v-for="item in headBankList" :key="item.bank_id" :label="item.bank_name" :value="{bankDeep: 2, bank_id: item.bank_id, Id: ''}"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="所属省分行"  v-if="bankType == 'areaBank' || bankType == 'cityBank'" prop="cityParent_id">
                        <el-select v-model="editorForm.cityParent_id" value-key="bank_id" filterable placeholder="请选择所属省分行" @change="checkBank">
                            <template v-for="item in proviceBankListOp">
                                    
                                <el-option  :key="item.bank_id" :label="item.bank_name" :value="{bankDeep: 3, bank_id: item.bank_id, Id: item.province,type: 'cityList'}"></el-option>
                            </template>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="所属银行市分行" v-if="bankType == 'areaBank'" prop="aresParent_id">
                        <el-select v-model="editorForm.aresParent_id"  value-key="bank_id" filterable placeholder="请选择所属银行市分行" @change="checkBank">
                            <el-option v-for="item in cityBankListOp" :key="item.bank_id" :label="item.bank_name" :value="{bankDeep: 4, bank_id: item.bank_id, Id: item.city,type: 'areaList'}"></el-option>
                        </el-select>
                    </el-form-item>
                    <!-- 填写类型 -->
                    <el-form-item label="类型" v-if="bankType != 'headBank'">
                        <el-radio-group v-model="editorType">
                            <el-radio :label="1">填写</el-radio>
                            <el-radio v-show="!isEditor" :label="2">文件导入</el-radio>
                        </el-radio-group>
                    </el-form-item>  
                    <!-- 所在地区 -->
                    <template v-if="editorType == 1">
                        <el-form-item label="所在省份" prop="province" v-if="bankType == 'proviceBank'">
                            <el-select v-model="editorForm.province" filterable placeholder="请选择所在省份">
                                <el-option v-for="item in provices" :key="item.id" :label="item.name" :value="item.id"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="所在城市" prop="city" v-if="bankType == 'cityBank'">
                            <el-select v-model="editorForm.city" filterable placeholder="请选择所在城市">
                                <el-option v-for="item in citys" :key="item.id" :label="item.name" :value="item.id"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="所在区县" prop="area" filterable v-if="bankType == 'areaBank'">
                            <el-select v-model="editorForm.area" placeholder="请选择所在区县">
                                <el-option v-for="item in areas" :key="item.id" :label="item.name" :value="item.id"></el-option>
                            </el-select>
                        </el-form-item>
                    </template>
                    <!-- 银行名称 -->
                    <template v-if="editorType == 1">
                        <el-form-item v-if="bankType == 'proviceBank'" label="省分行名称" prop="bank_name">
                            <el-input type="text" size='small' v-model="editorForm.bank_name"></el-input>
                        </el-form-item>
                        <el-form-item v-if="bankType == 'cityBank'" label="市分行名称" prop="bank_name">
                            <el-input type="text" size='small' v-model="editorForm.bank_name"></el-input>
                        </el-form-item>
                        <el-form-item v-if="bankType == 'areaBank'" label="支名称" prop="bank_name">
                            <el-input type="text" size='small' v-model="editorForm.bank_name"></el-input>
                        </el-form-item>
                    </template>
                    <template v-if="editorType == 2">
                        <el-form-item  label="选择文件" prop="permission_name">
                            <div class="uploadBox">
                                <el-upload
                                    class="upload-demo"
                                    action="//"
                                    :on-change="fileChange"
                                    :auto-upload="false"
                                    multiple
                                    :limit="1"
                                    :file-list="fileList">
                                        <div class="unload">
                                            <el-button size="mini">选择</el-button>
                                        </div>
                                </el-upload>
                                <div class="downloadTemp"><a href="https://cdn.wecloudx.com/inputBankTemplate.xlsx">下载导入模板</a></div>
                            </div>
                        </el-form-item>
                    </template>
                    <el-form-item class="dialogFooter">
                        <!-- <el-button type="primary" size='small' @click="submiteEditor('editorForm')">查询</el-button> -->
                            <el-button @click="resetForm('editorForm')">取 消</el-button>
                            <el-button type="primary" @click="submiteEditor('editorForm')">确 定</el-button>
                    </el-form-item>
                </el-form> 
            </template>
        </el-dialog>
    </div>
</template>

<script>
    import * as Public from '../api/public'
    import * as Bank from '../api/bank'
    export default {
        data() {
            return {
                loading: false,
                headBankCheckedId: 0,
                proviceBankCheckedId: 0,
                cityBankCheckedId: 0,
                areaBankCheckedId: 0,
                headBankList: [],
                proviceBankList: [],
                cityBankList: [],
                areaBankList: [],
                dialogTitle: '添加总行',
                bankType: 'headBank',
                editorForm: {
                    headParent_id: '',
                    proviParent_id: '',
                    cityParent_id: '',
                    aresParent_id: '',
                    province: '',
                    city: '',
                    area: '',
                    bank_name: ''
                },
                editorRules: {},
                dialogVisible: false,
                parentMunList: [],
                editorType: 1,
                fileList: [],
                checkedHeadBanks: [],
                allBanks: [],  //所有银行
                proviceBankParent_id: '',//省属父级
                cityBankParent_id: '',//市属父级
                areaBankParent_id: '',//区属父级
                provices: [],//省列表
                citys: [],//市列表
                areas: [],//县区列表
                enum_str: '',//银行编码

                provinceId: '',//省id
                cityId: '',//市id

                proviceBankListOp: [],
                cityBankListOp: [],
                areaBankListOp: [],
                edtiorId: '',
                editorRules: {
                    headParent_id: [{required: true, message: '请选择银行', trigger: 'blur' }],
                    proviParent_id: [{required: true, message: '请选择所属银行', trigger: 'blur' }],
                    cityParent_id: [{required: true, message: '请选择所属省分行', trigger: 'blur' }],
                    aresParent_id: [{required: true, message: '请选择所属银行市分行', trigger: 'blur' }],
                    province: [{required: true, message: '请选择所在省份', trigger: 'blur' }],
                    city: [{required: true, message: '请选择所在城市', trigger: 'blur' }],
                    area: [{required: true, message: '请选择所在区县', trigger: 'blur' }],
                    bank_name: [{required: true, message: '请填写名称', trigger: 'blur' }],
                },
                file: '',
                isEditor: false
            }
        },
        watch: {
            headBankList(value) {
                if(value.length) {
                    this.getBankList(2,value[0].bank_id)
                } else {
                    this.proviceBankList =[]
                }
            },
            proviceBankList(value) {
                if(value.length) {
                    this.getBankList(3,value[0].bank_id)
                } else {
                    this.cityBankList =[]
                }
            },
            cityBankList(value) {
                if(value.length) {
                    this.getBankList(4,value[0].bank_id)
                } else {
                    this.areaBankList =[]
                }
            }
        },
        created() {
            this.getAllBanks()
            this.getBankList(1)
            this.dictionariesP(1,0,1,'provinceList')  //省级行政
        },
        methods: {
            //获取总、分银行列表
            getBankList(bank_level,parent_id) {
                this.loading = true
                Bank.Bank({bank_level,parent_id}).then(res => {
                    switch (bank_level) {
                        case 1: this.headBankList = res.list
                            break;
                        case 2: this.proviceBankList = res.list
                            break;
                        case 3: this.cityBankList = res.list
                            break;
                        case 4: this.areaBankList = res.list
                            break;
                    }
                    // this.headBankList = res.list
                    this.loading = false
                    
                })
            },
            chekedBank(type,index,item) {
                this.enum_str = item.enum_str
                if(type == 'headBank') {
                    this.headBankCheckedId = index;
                    this.proviceBankParent_id = item.bank_id
                    this.getBankList(2, item.bank_id)
                }
                if(type == 'proviceBank') {
                    this.proviceBankCheckedId = index;
                    this.cityBankParent_id = item.bank_id
                    this.provinceId = item.province
                    this.getBankList(3, item.bank_id)
                    this.dictionariesP(1,item.province,1,'cityList')  //市级行政
                }
                if(type == 'cityBank') {
                    this.cityBankCheckedId = index;
                    this.areaBankParent_id = item.bank_id
                    this.cityId = item.city
                    this.getBankList(4, item.bank_id)
                    this.dictionariesP(1,item.city,1,'areaList')  //区级行政
                }
                if(type == 'areaBank') {
                    this.areaBankCheckedId = index
                }
            },
            //添加编辑
            addBank(type,item,isEditor) {
                this.isEditor = isEditor
                this.bankType = type
                switch (type) {
                    case 'headBank':  this.dialogTitle =  isEditor? '编辑总行' : '添加总行'  //总行
                        break;
                    case 'proviceBank':  this.dialogTitle = isEditor? '编辑省分行' : '添加省分行'    //省行
                        break;
                    case 'cityBank':  this.dialogTitle = isEditor? '编辑市分行' : '添加市分行'    //市行
                        break;
                    case 'areaBank':  this.dialogTitle = isEditor? '编辑支行' : '添加支行'   //支行
                        break;
                }
                this.dialogVisible = true
                if( isEditor ) { //如果是编辑  请求详情
                    this.edtiorId = item.bank_id
                    Bank.BankDetail(item.bank_id).then(res => {
                        this.$set(this.editorForm, 'bank_name',res.bank_name)
                    })
                }
            },
            //重置表单
            resetForm(formName) {
                this.dialogVisible = false
                this.editorType = 1
                this.$refs[formName].resetFields();
            },
            //提交编辑表单
            submiteEditor(formName) {
                let type = this.bankType
                this.$refs[formName].validate((valid) => {
                    if ( valid ) {
                        if(type == 'headBank') { //总行添加
                            let obj = {
                                bank_level: 1,
                                bank_name: this.checkedHeadBanks[0].name,
                                enum_str: this.checkedHeadBanks[0].enum_str,
                                parent_id: this.checkedHeadBanks[0].parent_id
                            }
                            
                            Bank.AddBank(obj).then(res => {
                                this.getBankList(1, obj.parent_id)
                                this.checkedHeadBanks = []
                                this.resetForm('editorForm')
                            })
                        } else if(type == 'proviceBank') { //省行添加
                            let obj = {
                                bank_level: 2,
                                parent_id: this.editorForm.proviParent_id.bank_id,
                                bank_name: this.editorForm.bank_name,
                                province: this.editorForm.province
                            }
                            this.HandelCallApi(obj)
                        } else if(type == 'cityBank') { //省行添加
                            let obj = {
                                bank_level: 3,
                                parent_id: this.editorForm.cityParent_id.bank_id,
                                bank_name: this.editorForm.bank_name,
                                province: this.editorForm.province,
                                city: this.editorForm.city
                            }
                            this.HandelCallApi(obj)
                        } else if(type == 'areaBank') { //省行添加
                            let obj = {
                                bank_level: 4,
                                parent_id: this.editorForm.aresParent_id.bank_id,
                                bank_name: this.editorForm.bank_name,
                                province: this.editorForm.province,
                                city: this.editorForm.city,
                                area: this.editorForm.area
                            }
                            this.HandelCallApi(obj)
                        }
                        if(this.edtiorId) {
                            this.edtiorId = ''
                        }
                        this.dialogVisible = false
                        // this.resetForm('editorForm')
                    } else {
                        this.loading = false
                    }
                })
            },
            HandelCallApi(obj) {
                let formDate = new FormData()
                let params = {}
                if(this.editorType == 2) { //批量导入
                    formDate.append('file',this.file.raw)
                    formDate.append('bank_level',obj.bank_level)
                    formDate.append('parent_id',obj.parent_id)
                    params = formDate
                    Bank.batchImport(params).then(res => {
                        this.getBankList(obj.bank_level, obj.parent_id)
                        this.resetForm('editorForm')
                    })
                } else {
                    params = obj
                    if(this.edtiorId) {
                        Bank.eidtBank(this.edtiorId, params).then(res => {
                            this.getBankList(params.bank_level, params.parent_id)
                            this.resetForm('editorForm')
                        })
                    } else {
                        Bank.AddBank(params).then(res => {
                            this.getBankList(params.bank_level, params.parent_id)
                            this.resetForm('editorForm')
                        })
                    }
                }
            },
            //删除
            handelDelete(deep,item) {
                if(deep) {
                    Bank.deleteBank(item.bank_id).then(res => {
                        this.getBankList(deep, item.parent_id)
                    })
                }
            },

            handleClose(tag) {
                this.checkedHeadBanks.splice(this.checkedHeadBanks.indexOf(tag), 1);
            },
            //获取所有银行
            getAllBanks() {
                let obj = {
                    page_size: 999,
                    current_page:1,
                    dictionary_id:2,
                    id:0,
                    search_depth:1
                }
                Public.dictionaries(obj).then(res=> {
                    if(res.length) {
                        let list = []
                        res.map(item => {
                            if(item.name != 'root') {
                                list.push(item)
                            }
                        })
                        this.allBanks= list
                    }
                })
            },
            checkHeadbankChange(value){ //银行id值
                this.allBanks.map(item => {
                    if(item.id == value) {
                        // this.checkedHeadBanks.push(item)
                        this.checkedHeadBanks[0] = item
                    }
                })

            },
            checkBank(value) {              //银行选择（表单）deep  =>1省级、2市级、3区级
                if( value ) {
                    if(value.type) {
                        this.dictionariesP(1,value.Id,1,value.type)
                    }
                    this.getBankListOptions(value.bankDeep, value.bank_id)
                }
            },

            //获取总、分银行选项列表
            getBankListOptions(bank_level,parent_id) {
                // this.loading = true
                
                Bank.Bank({bank_level,parent_id}).then(res => {
                    switch (bank_level) {
                        // case 1: this.headBankList = res.list
                        //     break;
                        case 2: this.proviceBankListOp = res.list
                            break;
                        case 3: this.cityBankListOp = res.list
                            break;
                        case 4: this.areaBankListOp = res.list
                            break;
                    }
                })
            },

            //行政区划
            dictionariesP(dictionary_id,id,search_depth,type ) {
                let obj = {
                    page_size: 999,
                    current_page:1,
                    dictionary_id,
                    id,
                    search_depth 
                }
                Public.dictionaries(obj).then(res=> {
                    if(res.length) {
                        let list = []
                        res.map(item => {
                            if(item.name != 'root') {
                                list.push(item)
                            }
                        })
                        switch (type) {
                            case 'provinceList': this.provices = list
                                break;
                            case 'cityList': this.citys = list
                                break;
                            case 'areaList': this.areas = list
                                break;
                        }
                    }
                })
            },
            //上传文件
            fileChange(file, fileList) {
                this.file = file
            },
        },
    }
</script>

<style lang="scss" scoped>
    .titl{
        font-weight: bold; 
        margin-bottom: 20px;  
    }
    .grid-content{
        height: 50px;
        border: 1px solid #e4e4e4;
        height: 80vh;
        // overflow: scroll;
        box-sizing: border-box;
        .titleBox{
            font-size: 15px;
            padding: 0 8px;
            height: 50px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            background: #00c520;
            color: #fff;
            .el-button--text {
                color: #fff;
            }
            .text{
                color: #fff;
            }
        }
        .banList{
            height: 76vh;
            overflow-y: scroll;
            .bankBox{
                display: flex;
                justify-content: space-between;
                align-items: center;
                font-size: 10px;
                padding: 0 5px;
                height: 40px;
                .bankTitle{
                    font-size: 15px;
                }
                .bankBtn{
                    display: flex;
                    width: 70px;
                    justify-content: space-between;
                    align-items: center;
                }
            }
            .checked{
                background: #f2f2f2;
            }
        }
    }
    .row-bg {
        padding: 10px 0;
        background-color: #f9fafc;
    }
    
    ::-webkit-scrollbar {
        width: 4px; /*对垂直流动条有效*/
        // background: #000;
    }
    /*定义滑块颜色、内阴影及圆角*/
    ::-webkit-scrollbar-thumb{
        border-radius: 7px;
        -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
        background-color: #9c9c9c;
    }
    .headBankList{
        padding: 3px;
        flex-wrap: wrap;
        margin-top: 20px;
        height: 150px;
        width: 89%;
        background: #f2f2f2;
        .el-tag{
            margin: 3px;
        }
    }
    .uploadBox{
        display: flex;
        .unload{
            display: flex;
            align-items: center;
            .el-button--mini{
                height: 35px;
            }
        }
        .downloadTemp{
            margin-left: 5px;
            color: #42b983;
            a{
                color: #42b983;
                text-decoration: none;
            }
        }
    }
</style>