import request from '../utils/request'

//管理员列表
export function Manager (params) {
    return request({
        url: '/v1/bq/users',
        method: 'get',
        params
    })
}

//新增管理员
export function AddManager (params) {
    return request({
        url: '/v1/bq/users',
        method: 'post',
        data: params
    })
}

//详情
export function ManagerDetail (id) {
    return request({
        url: `/v1/bq/users/${id}`,
        method: 'get'
    })
}

//更新
export function eidtManager (id,params) {
    return request({
        url: `/v1/bq/users/${id}`,
        method: 'put',
        data: params
    })
}

//删除
export function deleteManager (id) {
    return request({
        url: `/v1/bq/users/${id}`,
        method: 'delete'
    })
}

//禁用
export function pauseManager (params) {
    return request({
        url: `/v1/bq/users/pause`,
        method: 'put',
        data: params
    })
}