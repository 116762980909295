import request from '../utils/request'

//模板列表
export function Template (params) {
    return request({
        url: '/v1/bq/bank-qrcode-templates',
        method: 'get',
        params
    })
}

//新增模板
export function AddTemplate (params) {
    return request({
        url: '/v1/bq/bank-qrcode-templates',
        method: 'post',
        data: params
    })
}

//详情
export function TemplateDetail (id) {
    return request({
        url: `/v1/bq/bank-qrcode-templates/${id}`,
        method: 'get'
    })
}

//更新
export function eidtTemplate (id,params) {
    return request({
        url: `/v1/bq/bank-qrcode-templates/${id}`,
        method: 'put',
        data: params
    })
}

//删除
export function deleteTemplate (id) {
    return request({
        url: `/v1/bq/bank-qrcode-templates/${id}`,
        method: 'delete'
    })
}