<template>
    <div>
        <el-cascader
        ref="checkbox"
        v-model="value"
        :options="PermissionsTreeList"
        :props="props"
        @change="handleChange"
        clearable></el-cascader>
    </div>
</template>

<script>
    import * as Authority from '../../api/authority'
    export default {
        props:['extra','default'],
        data() {
            return {
                // value: [[1,3,4],[1,2]],
                props: { multiple: true },
                PermissionsTreeList: [],
                menus:[]
            }
        },
        computed: {
            // value() {
            //     return this.extra
            // }
            value: {
                get() {
                    this.handleChange(this.extra)
                    return this.extra
                },
                set(val) {
                    return val
                }
            }
        },
        mounted(){
            //获取权限树
           this.getPermissTree()
        //    this.getValues([[1,3,4],[1,2]])
        },
        methods: {
            handleChange(value) {
                if(value.length) {
                    let menus = []
                    value.map(item => {
                        menus.push(...item)
                        menus = Array.from(new Set(menus))
                    })
                    this.menus = menus
                    let role_permission_struct ={
                        menus,
                        extra: this.getValues(value)
                    }
                    this.$emit('getmenus',role_permission_struct)
                } else {
                    this.$emit('getmenus',null)
                }
            },
            // 二维数组转字符串
            getValues(arr) {
                let newArrToString = ''
                let s = ''
                for (let i = 0; i < arr.length; i++) {
                    s += '['
                    for(let j = 0; j<arr[i].length-1; j++){
                        s += arr[i][j] + ','
                    }
                    s += arr[i][arr[i].length-1] + '],'
                }
                return '[' + s.substr(0, s.length-1) + ']'
            },
            //获取权限树
            getPermissTree() {
                Authority.PermissionsTree().then(res => {
                    // console.log('==============获取的权限树',res.list);
                    this.PermissionsTreeList = res.list
                    if(res.list && res.list.length) {
                        this.handleTreeCheckStatus(res.list)
                    }
                })
            },
            handleTreeCheckStatus(list) {
                // debugger
                    list.map(item => {
                        item.label = item.permission_name,
                        item.value = item.permission_id
                        if(item.nodes) {  //存在子节点（二级路由)
                            item.children = item.nodes
                            this.handleTreeCheckStatus(item.children)
                         } else if (item.actions){  //存在操作
                            item.children = item.actions
                            this.handleTreeCheckStatus(item.children)
                         }
                    })
            }
        },
    }
</script>

<style lang="scss">

</style>