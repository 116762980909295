import CryptoJS from 'crypto-js'
import { JSEncrypt } from 'jsencrypt'
const c_t = '1609228459000';
const pubKey = `-----BEGIN PUBLIC KEY-----
MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQDGx3BPOc6T4mHV3syeLKL92DjK
n7W//w4wjXH+F/CP0S8SEuJ/JLYoo79/DgPz7i6odx+OxmWQO2WPK7aDLauJLczn
m3Xp/Ek31PDcsEZWJvLyAEgP8wfWnLD7CGsAXhE0b50PMZ5C8/zoN3j+wop6e/Rv
ww8+cvkfYTCdLzugLQIDAQAB
-----END PUBLIC KEY-----`
    
 /**
  * @name: encryPwd
  * @description: 密码加密
  * @param {*} pwd <string> 需要加密的数据源
  * @return {*}
  */
 function encryPwd(pwd){
    const pp = CryptoJS.MD5(pwd)+','+ c_t +','+ Math.random();
    const encrypt = new JSEncrypt();
    encrypt.setPublicKey(pubKey);
    const dd = encodeURIComponent(encrypt.encrypt(pp))
    return encodeURIComponent(encrypt.encrypt(pp));
 }


  // 获取地址中的参数
   function getUrlKey(name) {
      return decodeURIComponent((new RegExp('[?|&]' + name + '=' + '([^&;]+?)(&|#|;|$)').exec(location.href) || [, ""])[1].replace(/\+/g, '%20')) || null;
   };

   //手机号隐藏中间值
   function geTel(tel){
      var reg = /^(\d{3})\d{4}(\d{4})$/;  
      return tel.replace(reg, "$1****$2");
   }

 export { encryPwd, getUrlKey, geTel }