import Vue from 'vue'
import axios from 'axios'
import { rebuildUrl } from './getSign'
import { Message } from 'element-ui'
import config from '../config/apiConfig'

import https from 'https'

const qs = require('qs')

// 创建axios实例
const service = axios.create({
  timeout: 60000,
  baseURL: config.host,
  // headers: {
    //   Authorization: `Bearer ${token}`,
    //   // Authorization: config.token,
    // },
    httpsAgent: new https.Agent({
      rejectUnauthorized: false
    }),
    paramsSerializer: params => qs.stringify(params, { arrayFormat: 'repeat' })
  })
  
service.interceptors.request.use(config => {
  /** 设置令牌 */
  let token = localStorage.getItem('token')
  if (token) {
    config.headers['Authorization'] = `Bearer ${token}`
  }

  return config
})

service.interceptors.response.use(
  res => {
     if (res.status === 200) {
       if (res.data.code === 0) {
        return Promise.resolve(res.data.data)
       } else if ( res.data.code === 10007 ) { //账号未绑定
        return Promise.resolve(res.data)
       } else {
        return Message.error(res.data.message)
       }
     } else if(res.status === 403) {
        localStorage.removeItem('token')
        window.location.href = '/'
     } else {
      Message.error(res.status)
      return Promise.reject(res)
     }
  
   },
   error => {
    Message.error(error)
    let { response } = error
    if( response ) {
      if( response.status ) {
        switch (status) {
          // case 403:
            
          //   break;
          // case 404:
          
          //   break;
        
          // default:
          //   break;
        }
      }
      return Promise.reject(response)
    }
  }
)

export default function request(options) {
  let sing = rebuildUrl(options.method, options.url, options.params,options.isDownload)
  options.params = sing.q
  options.url = sing.fullUrl
  if(options.isDownload) {  //下载任务
    let a = document.createElement('a')
        a.style = "display:none"
        document.body.appendChild(a)

        let url = config.host + sing.fullUrl
        a.href = url
        a.download = ''
        a.click()
        document.body.removeChild(a)

        return false
  } else {
    return new Promise(( resolve, reject ) => {
        service(options).then(resolve).catch(reject)
    })
  }
}
