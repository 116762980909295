import request from '../utils/request'

//报表导入列表
export function BindList (params) {
    return request({
        url: '/v1/bq/report-import-tasks',
        method: 'get',
        params
    })
}

//新增报表（导入）
export function AddBind (params) {
    return request({
        url: '/v1/bq/report-import-tasks',
        method: 'post',
        data: params
    })
}

//下载失败导入数据1
export function BindDetail (params) {
    return request({
        url: `/v1/bq/report-import-tasks/fail`,
        method: 'get',
        params,
        // isDownload: true,
    })
}

// 先调1，获取hash值然后调2

//下载失败导入数据2
export function Bindfaile (params) {
    return request({
        url: `/v1/bq/resources`,
        method: 'get',
        params,
        isDownload: true,
    })
}