<template>
    <div>
        <div class="bankItemBox">
            <div class="title">总行权限</div>
            <div class="bankList">
                <el-tag
                    class="el-tag"
                    size="small"
                    v-for="tag in checkedHeadBanks"
                    :key="tag.bank_name"
                    closable
                    @close="handleClose(tag, 'head')"
                    type="success">
                    {{getName(tag)}}
                </el-tag>
            </div>
            <el-button @click="addBanks('head')" type='primary' style="marginLeft: 10px" size='mini'><i class="el-icon-plus"></i> 添加</el-button>
        </div>
        <div class="bankItemBox">
            <div class="title">省分行权限</div>
            <div class="bankList">
                <el-tag
                    class="el-tag"
                    size="small"
                    v-for="tag in checkedproviBanks"
                    :key="tag.bank_name"
                    closable
                    @close="handleClose(tag, 'provi')"
                    type="success">
                    {{getName(tag)}}
                </el-tag>
            </div>
            <el-button @click="addBanks('provi')" type='primary' style="marginLeft: 10px" size='mini'><i class="el-icon-plus"></i> 添加</el-button>
        </div>
        <div class="bankItemBox">
            <div class="title">市分行权限 (包含直辖市、特别行政区)</div>
            <div class="bankList">
                <el-tag
                    class="el-tag"
                    size="small"
                    v-for="tag in checkedcityBanks"
                    :key="tag.bank_name"
                    closable
                    @close="handleClose(tag, 'city')"
                    type="success">
                    {{getName(tag)}}
                </el-tag>
            </div>
            <el-button @click="addBanks('city')" type='primary' style="marginLeft: 10px" size='mini'><i class="el-icon-plus"></i> 添加</el-button>
        </div>
        <div class="bankItemBox">
            <div class="title">支行权限 (不包含直辖市、特别行政区)</div>
            <div class="bankList">
                <el-tag
                    class="el-tag"
                    size="small"
                    v-for="tag in checkedareaBanks"
                    :key="tag.bank_name"
                    closable
                    @close="handleClose(tag, 'area')"
                    type="success">
                    {{getName(tag)}}
                </el-tag>
            </div>
            <el-button @click="addBanks('area')" type='primary' style="marginLeft: 10px" size='mini'><i class="el-icon-plus"></i> 添加</el-button>
        </div>
        <el-dialog
          :title="dialogTitle"
          :visible.sync="dialogVisible"
          width="60%"
          :close-on-click-modal="false"
          :show-close="true"
          :before-close="close"
        >
            <template>
                <el-form v-show="bankType != 'head'" :model="editorForm" :inline="true" ref="editorForm" class="demo-ruleForm">
                              
                    <el-form-item prop="parent_id">
                        <el-select size='mini' v-model="editorForm.headBankId"  value-key="bank_id" placeholder="请选择银行" @change="checkBank">
                            <el-option v-for="(item, index) in headBankList" :key="index" :label="item.bank_name"  :value="{bankDeep: 2, bank_id: item.bank_id}"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item prop="parent_id" v-show=" bankType == 'area' || bankType == 'city'">
                        <el-select size='mini' v-model="editorForm.proviceBankId" value-key="bank_id" placeholder="请选择省分行" @change="checkBank">
                            <el-option v-for="(item, index) in proviceBankList" :key="index" :label="item.bank_name"  :value="{bankDeep: 3, bank_id: item.bank_id}"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item prop="parent_id" v-show=" bankType == 'area'">
                        <el-select size='mini' v-model="editorForm.cityBankId" value-key="bank_id" placeholder="请选择市分行" @change="checkBank">
                            <el-option v-for="(item, index) in cityBankList" :key="index" :label="item.bank_name"  :value="{bankDeep: 4, bank_id: item.bank_id}"></el-option>
                        </el-select>
                    </el-form-item>
                    <!-- <el-form-item prop="parent_id">
                      <el-input type="text" size='mini' :clearable="true"></el-input>
                    </el-form-item> -->
                    <el-form-item class="dialogFooter">
                        <el-button size='mini' type="primary" @click="search">查询</el-button>
                    </el-form-item>
                </el-form> 
            </template>
            <el-checkbox :indeterminate="isIndeterminate" v-model="checkAll" @change="handleCheckAllChange">全选</el-checkbox>
            <div style="margin: 15px 0;"></div>
            <el-checkbox-group v-model="checkedCities" @change="handleCheckedCitiesChange">
                <el-checkbox v-for="item in checkBoxs" :label="item.bank_id" :key="item.bank_id">{{item.bank_name}}</el-checkbox>
            </el-checkbox-group>
        </el-dialog>
    </div>
</template>

<script>
    import * as Bank from '../../api/bank'
    export default {
        props: ["banks"],
        data() {
            return {
                dialogVisible: false,
                permission_id: '',
                editorForm: {
                    headBankId: '',
                    proviceBankId: '',
                    cityBankId: ''
                },
                checkedHeadBanks:[],
                checkedproviBanks:[],
                checkedcityBanks:[],
                checkedareaBanks:[],
         
                headBankList: [],
                proviceBankList: [],
                cityBankList: [],
                areaBankList: [],

                bankType: 'head',
                dialogTitle: '选择总行',

                checkAll: false,
                checkedCities: [],
                isIndeterminate: true,
                checkBoxs: [],

                cheked: [],
                AllBanks: []
            }
        },
        mounted() {
            this.getBankList(1)
        },
        
        watch: {
            banks(value) {
                // console.log('监听',value);
                this.getAllBanks(value)

            }
            // cheked(value) {
            //     switch (this.bankType) {
            //         case 'head': this.checkedHeadBanks = value
            //             break;
            //         case 'provi': this.checkedproviBanks = value
            //             break;
            //         case 'city': this.checkedcityBanks = value
            //             break;
            //         case 'area': this.checkedareaBanks = value
            //             break;
            //     }
            // }
        },
        methods: {
            handleClose(tag,type) {
                // this.checkedHeadBanks.splice(this.checkedHeadBanks.indexOf(tag), 1);
                switch (type) {
                    case 'head': this.checkedHeadBanks.splice(this.checkedHeadBanks.indexOf(tag), 1)
                        break;
                    case 'provi': this.checkedproviBanks.splice(this.checkedproviBanks.indexOf(tag), 1)
                        break;
                    case 'city': this.checkedcityBanks.splice(this.checkedcityBanks.indexOf(tag), 1)
                        break;
                    case 'area': this.checkedareaBanks.splice(this.checkedareaBanks.indexOf(tag), 1)
                        break;
                }
            },
            //获取总、分银行列表
            getBankList(bank_level,parent_id) {
                this.loading = true
                Bank.Bank({bank_level,parent_id,page_size: 999}).then(res => {
                    switch (bank_level) {
                        case 1: this.headBankList = res.list  //总
                            break;
                        case 2: this.proviceBankList = res.list  //省
                            break;
                        case 3: this.cityBankList = res.list  //市
                            break;
                        case 4: this.areaBankList = res.list  //县区
                            break;
                    }
                    switch (this.bankType) {
                        case 'head': this.checkBoxs = res.list
                            break;
                    }
                    this.loading = false
                })
            },
            getAllBanks(banks) {
                Bank.Bank({page_size: 999}).then(res => {
                    this.AllBanks = res.list
                    if(banks && banks.length) {
                        this.getDefaultBank(res.list,banks)
                    }
                })
            },
            //获取详情中的银行过滤
            getDefaultBank(allList,banks) {
                let banksList = []
                banks.forEach(id => {
                    allList.forEach(allId => {
                        if(id == allId.bank_id) {
                            banksList.push(allId)
                        }
                    })
                });
                if(banksList && banksList.length) {
                    this.filterBanksToGroup(banksList)
                }
            },
            //银行归类
            filterBanksToGroup(banksList){
                banksList.forEach(item => {
                    switch (item.bank_level) {
                        case 1: this.checkedHeadBanks.push(item.bank_id)
                            break;
                        case 2: this.checkedproviBanks.push(item.bank_id)
                            break;
                        case 3: this.checkedcityBanks.push(item.bank_id)
                            break;
                        case 4: this.checkedareaBanks.push(item.bank_id)
                            break;
                    }
                })
            },
            search() {
                switch (this.bankType) {
                    case 'head': this.checkBoxs = this.headBankList
                        break;
                    case 'provi': this.checkBoxs = this.proviceBankList
                        break;
                    case 'city': this.checkBoxs = this.cityBankList
                        break;
                    case 'area': this.checkBoxs = this.areaBankList
                        break;
                }
            },
            checkBank(value) {              //银行选择（表单）deep  =>1省级、2市级、3区级
                if( value ) {
                    this.getBankList(value.bankDeep, value.bank_id)
                }
            },
            addBanks(type) {
                this.bankType = type
                this.dialogVisible = true
                switch (type) {
                    case 'head': this.dialogTitle = '选择总行'
                        break;
                    case 'provi': this.dialogTitle = '选择省分行'
                        break;
                    case 'city': this.dialogTitle = '选择市分行'
                        break;
                    case 'area': this.dialogTitle = '选择支行'
                        break;
                }
            },
            //全选
            handleCheckAllChange(val) {
                let list = []
                // this.headBankList.map(item => {
                //     list.push(item.bank_id)
                // })
                if(this.bankType == 'head') {
                    this.headBankList.map(item => {
                        list.push(item.bank_id)
                    })
                } else if(this.bankType == 'provi') {
                    this.proviceBankList.map(item => {
                        list.push(item.bank_id)
                    })
                } else if(this.bankType == 'city') {
                    this.cityBankList.map(item => {
                        list.push(item.bank_id)
                    })
                }  else if(this.bankType == 'area') {
                    this.areaBankList.map(item => {
                        list.push(item.bank_id)
                    })
                }
                this.checkedCities = val ? list : [];
                this.isIndeterminate = false;
            },
            handleCheckedCitiesChange(value) {
                let list = []
                switch (this.bankType) {
                    case 'head': list= this.headBankList
                        break;
                    case 'provi': list= this.proviceBankList
                        break;
                    case 'city': list= this.cityBankList
                        break;
                    case 'area': list= this.areaBankList
                        break;
                }
                // this.cheked = value
                switch (this.bankType) {
                    case 'head': this.checkedHeadBanks = value
                        break;
                    case 'provi': this.checkedproviBanks = value
                        break;
                    case 'city': this.checkedcityBanks = value
                        break;
                    case 'area': this.checkedareaBanks = value
                        break;
                }
                let checkedCount = value.length;
                this.checkAll = checkedCount === list.length;
                this.isIndeterminate = checkedCount > 0 && checkedCount < list.length;
            },
            //关闭
            close() {
                this.$set(this.editorForm, 'headBankId', '')
                this.$set(this.editorForm, 'proviceBankId', '')
                this.$set(this.editorForm, 'cityBankId', '')
                // this.cheked = this.checkBoxs
                this.checkBoxs = []
                this.checkedCities = []
                this.dialogVisible = false
                this.checkAll = false
            },
            getName(id) {
                let name = ''
                this.AllBanks.map(item => {
                    if(item.bank_id == id) {
                        name = item.bank_name
                    }
                })
                return name
            }
        },
    }
</script>

<style lang="scss" scoped>
    .bankItemBox{
        margin-bottom: 10px;
        width: 60vw;
        padding: 0 10px 10px 10px;
        height: auto;
        background: #f9f9f9;
        .title{
            font-size: 13px;
            font-weight: bold;
        }
        .bankList{
            width: 59vw;
            padding: 5px;
            // background: rgb(185, 163, 163);
            display: flex;
            align-items: flex-start;
            flex-wrap: wrap;
            .el-tag{
                 margin: 3px !important;
            }
        }
}
</style>