<template>
    <div v-loading="loading">
        <div class="title">{{role_id ? '编辑角色': '添加角色'}}</div>
        <el-form :model="detailForm" :rules="editorRules" ref="detailForm" label-width="100px" class="demo-ruleForm">
            <el-form-item label="角色名称" prop="role_name">
                <el-input type="text" style="width: 60%" v-model="detailForm.role_name" :clearable="true"></el-input>
            </el-form-item>    
            <el-form-item label="角色描述" prop="description">
                <el-input type="text" style="width: 60%" v-model="detailForm.description" :clearable="true"></el-input>
            </el-form-item>
            <el-form-item label="银行权限" prop="banks">
                <ChoseBank ref="choseBank" :banks="detailForm.role_permission_struct.banks"/>
            </el-form-item>
            <el-form-item label="菜单权限" prop="permissionsTree">
                <Permission @getmenus="getmenus" :extra="detailForm.role_permission_struct.extra" :default="detail"/>
                <!-- <Permission @getmenus="getmenus" :extra="[[1,3,4],[1,2]]"/> -->
            </el-form-item>                 
            <el-form-item>
                <div class="btnBox">
                    <el-button  @click="cancel">取消</el-button>
                    <el-button  class="subBtn" type="primary" @click="submit('detailForm')">确定</el-button>
                </div>
            </el-form-item>
        </el-form>
    </div>
</template>

<script>
    import ChoseBank from '../components/choseBank/choseBank.vue'
    import Permission from '../components/choseBank/permission.vue'
    import * as Role from '../api/role'
    export default {
        data() {
            return {
                loading: false,
                role_id: '',
                detailForm: {
                    role_name: '',
                    description: '',
                    role_permission_struct: {
                        banks: [],
                        menus: [],
                        extra: []
                    }
                },
                detail: {},
                editorRules: {
                    role_name: [{required: true, message: '请输入角色名称', trigger: 'blur' }],
                    // banks: [{required: true, message: '请输入角色名称', trigger: 'blur' }],
                    // role_name: [{required: true, message: '请输入角色名称', trigger: 'blur' }],
                    banks: [
                        {
                        required: true,
                        validator: (rule, value, callback) => {
                            if (!this.detailForm.role_permission_struct.banks.length) {
                            callback(new Error('请选择银行！'))
                            } else {
                            callback()
                            }
                        },
                        trigger: 'blur'
                        }
                    ],
                    permissionsTree: [
                        {
                        required: true,
                        validator: (rule, value, callback) => {
                            if (!this.detailForm.role_permission_struct.menus) {
                            callback(new Error('请选择权限！'))
                            } else {
                            callback()
                            }
                        },
                        trigger: 'blur'
                        }
                    ],
                },
                role_permission_struct: {}
            }
        },
        created() {
          let query = this.$route.query
          this.role_id = query.id
          if(query.id) {
              this.getDetail(query.id)
          }
        },
        components: {
            ChoseBank,
            Permission
        },
        methods: {
            //获取详情
            getDetail(id) {
                Role.RoleDetail(id).then(res => {
                    this.detail = res
                    if(res.role_permission_struct && res.role_permission_struct.extra) {
                        res.role_permission_struct.extra = eval(res.role_permission_struct.extra)
                    }
                    this.detailForm = res
                })
            },
            //提交搜索表单
            submit(formName) {
                let { checkedHeadBanks, checkedproviBanks, checkedcityBanks, checkedareaBanks} = this.$refs.choseBank
                let obj ={banks: [...checkedHeadBanks,...checkedproviBanks,...checkedcityBanks,...checkedareaBanks]}
                Object.assign(this.role_permission_struct,obj)
                // let params = this.detailForm
                this.detailForm.role_permission_struct = this.role_permission_struct
                this.$refs[formName].validate((valid) => {
                    if ( valid ) {
                        this.loading = true
                        if(this.role_id) {
                            Role.eidtRole(this.role_id, this.detailForm).then(res => {
                                this.loading = false
                                this.cancel()
                            })
                        } else {
                            Role.AddRole(this.detailForm).then(res => {
                                this.loading = false
                                this.cancel()
                            })
                        }
                    }
                })
            },
            cancel() {
                this.$router.go(-1)
            },
            //拿到的权限数据
            getmenus(val) {
                if(val) {
                    this.role_permission_struct = Object.assign(this.role_permission_struct, val)
                    // this.$set(this.detailForm, 'role_permission_struct', val)
                }
            }
        },
    }
</script>

<style lang="scss" scoped>
    .title{
        font-weight: bold;
        margin-bottom: 40px;
    }
    .btnBox{
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 50px;
        .subBtn{
            margin-left: 30px;
        }
    }
</style>