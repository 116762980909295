<template>
  <el-row class="layoutContianer">
    <!-- 顶部header -->
    <el-col :span='24' class="header">
      <div class="headerLeft">
        <img class="logo" src="http://wecloudx.oss-cn-shenzhen.aliyuncs.com/bankCodeStatic/logo.png" alt="">
        <span class="title">银行码管理系统</span>
      </div>
      <div class="right">
        <el-dropdown trigger="hover" class="dropdown">
					<span class="el-dropdown-link userinfo-inner">
            <img class="avatar" :src="info.avatar" />
            {{info.user_name}}
            <i class="el-icon-arrow-down el-icon--right"></i>
          </span>
					<el-dropdown-menu slot="dropdown" class="handleDown">
						<el-dropdown-item class="userName">{{info.user_name}}</el-dropdown-item>
						<el-dropdown-item class="logout" divided @click.native="logout">退出登录</el-dropdown-item>
					</el-dropdown-menu>
				</el-dropdown>
      </div>
    </el-col>
    <el-col :span='24' class="main">
      <aside class="aside">
        <el-menu
          default-active="0"
          class="el-menu-vertical-demo"
          background-color="#FAFAFA"
          active-text-color="#22ac38"
          >
          <template v-for="(item, index) in $router.options.routes">
            <!-- 非隐藏菜单 -->
            <template v-if="!item.hidden">
              <!-- 一级路由没有子项 -->
              <template v-if="!item.children">
                <el-menu-item v-role="item.routKey" v-if="!item.children" :key="index" :index='item.path'  @click="$router.push(item.path)">
                  <i class="el-icon-menu"></i>
                  <span slot="title">{{item.name}}</span>
                </el-menu-item>
              </template>
              <!-- 一级路由有子项 -->
              <template v-else>
                <template v-for="(itt, idd) in item.children">
                  <template v-if="!itt.children && !item.hidden">
                    <el-menu-item v-role="itt.routKey" v-if="!itt.hidden" :key="idd" :index='itt.path' @click="$router.push(itt.path)">
                      <i class="el-icon-menu"></i>
                      <span slot="title">{{itt.name}}</span>
                    </el-menu-item>
                  </template>
                  <template v-else>
                    <el-submenu v-role="itt.routKey" :key="idd" :index='itt.path'>
                      <template slot="title">
                        <i class="el-icon-location"></i>
                        <span>{{itt.name}}</span>
                      </template>
                      <template v-for="(ittmin, iddmin) in itt.children">
                        <el-menu-item v-role="ittmin.routKey" v-if="!ittmin.hidden" :key="iddmin" :index="ittmin.path" @click="$router.push(ittmin.path)">
                          <span slot="title">{{ittmin.name}}</span>
                        </el-menu-item> 
                      </template>
                    </el-submenu>
                  </template>
                </template>
              </template>
            </template>
          </template>
        </el-menu>
      </aside>
      <section class="section">
        <div class="grid-content bg-purple-light">
					<el-col :span="24" class="breadcrumb-container">
						<!-- <strong class="title">{{$route.name}}</strong> -->
						<el-breadcrumb separator-class="el-icon-arrow-right">
							<el-breadcrumb-item v-for="item in $route.matched" :key="item.path">
								{{ item.name }}
							</el-breadcrumb-item>
						</el-breadcrumb>
					</el-col>
          <!-- <hr> -->
					<el-col :span="24" class="content-wrapper">
						<transition name="fade" mode="out-in">
							<router-view></router-view>
						</transition>
					</el-col>
				</div>
      </section>
    </el-col>
  </el-row>
</template>

<script>
  import { mapGetters } from 'vuex'
  export default {
    data() {
      return {
        info: {}
      }
    },
    mounted() {
      this.getUserInfos()
    },
    methods: {
      ...mapGetters('Login',['getLoginInfos']),
      getUserInfos() {
        this.info = this.getLoginInfos()
      },
      logout(){
        localStorage.removeItem('token')
        this.$router.push('/')
      }
    },
  }
</script>

<style lang="scss" scoped>
  @import '../styles/vars.scss';
  .layoutContianer{
    margin: 0;
    padding: 0;
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100%;
    .header{
      height: 60px;
      line-height: 60px;
      background: $color-header;
      color: #fff;
      display: flex;
      justify-content: space-between;
      align-content: center;
      padding: 0 20px 0 10px;
      .headerLeft{
        display: flex;
        align-items: center;
        .logo{
          width: 185px;
          height: 38px;
        }
        .title{
          height: 25px;
          font-size: 20px;
          border-left: 1px solid #fff;
          display: flex;
          align-items: center;
          padding-left: 10px;
        }
      }
      .right{
        .dropdown{
          .userinfo-inner{
            display: flex;
            align-items: center;
            .avatar{
              width: 20px;
              height: 20px;
              margin-right: 10px;
            }
          }
          .handleDown{
            background: $color-header ;
            .userName{
              background: $color-header;
            }
            .logout{
              background: $color-header;
            }
          }
        }
      }
    }
    .main{
      // background: rgb(100, 189, 77);
      display: flex;
      position: absolute;
      top: 60px;
      bottom: 0;
      overflow: hidden;
      .aside{
        width: 230px;
        height: 100%;
        background: $color-side;
      }
      .section{
        width: 100%;
        .grid-content{
          .breadcrumb-container{
            // width: 100%;
            // background: #000;
            display: flex;
            align-items: center;
            height: 40px;
            border-bottom: 1px solid #f2f2f2;
            padding-left: 20px;
          }
          .content-wrapper{
            height: 85vh;
            padding: 20px 20px 50px 20px;
            overflow-y: scroll;
          }
        }
      }
    }
  }
  ::-webkit-scrollbar {
        width: 4px; /*对垂直流动条有效*/
        // background: #000;
    }
    /*定义滑块颜色、内阴影及圆角*/
    ::-webkit-scrollbar-thumb{
        border-radius: 7px;
        -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
        background-color: #9c9c9c;
    }
</style>