const UPDATELOGININFO = 'UPDATELOGININFO'
// import * as Login_Aip from '../../api/login'
export default {
    namespaced: true,
    state: {
        loginInfos: {}
    },
    getters: {
        getLoginInfos: state => {
            return state.loginInfos;
        }
    },
    mutations: {
        UPDATELOGININFO: (state, payload) => {
            state.loginInfos = payload;
        }
    },
    actions: {
        login({commit}, res) {
            localStorage.setItem('token', res.access_token)
            commit(UPDATELOGININFO, res);
        }
    }
};