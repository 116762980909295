<template>
    <div>
      <VTable :tabltData="tableData" :pagination="pagination" @pageChange="pageChange" v-loading="loading">
          <template slot="tableSearch">
              <el-form :model="searchForm" ref="searchForm" label-width="100px" class="demo-ruleForm" :inline="true">
                  <el-form-item>
                    <el-button v-role="'TempAdd'" type='primary' size='small' @click="handlAdd"><i class="el-icon-plus"></i>  添加</el-button>
                  </el-form-item>
                  <el-form-item prop="bank_id">
                      <el-select v-model="searchForm.bank_id" filterable clearable  placeholder="请选择银行" class="_el-select" size="small">
                          <el-option v-for="item in ownerBankList" v-show="item.bank_level === 1" :key="item.bank_id" :label="item.bank_name"
                              :value="item.bank_id">
                          </el-option>
                      </el-select>
                  </el-form-item>            
                  <el-form-item label="" prop="template_name">
                      <el-input type="text" size='small' clearable v-model="searchForm.template_name" placeholder="请输入模板名称"></el-input>
                  </el-form-item>  
                  <el-form-item>
                      <el-button type="primary" size='small' @click="submiteSearch('searchForm')">查询</el-button>
                  </el-form-item>
              </el-form>
          </template>
          <template slot='column'>
              <el-table-column prop="template_id" label="模板编号"></el-table-column>
              <el-table-column prop="bank_id" label="银行">
                <template slot-scope="scope">
                      <div>{{getBanName(scope.row.bank_id)}}</div>
                  </template>
              </el-table-column>
              <el-table-column prop="template_name" label="模板名称"></el-table-column>
              <el-table-column prop="logo" label="银行logo">
                  <template slot-scope="scope">
                      <div class="bindStatus">
                          <img class="bindWxImg bankLogo" :src="scope.row.template_attr_struct.logo" alt="">
                      </div>
                  </template>
              </el-table-column>
              <el-table-column prop="qrcode_logo" label="二维码logo">
                  <template slot-scope="scope">
                      <div class="bindStatus">
                          <img class="bindWxImg" :src="scope.row.template_attr_struct.qrcode_logo" alt="">
                      </div>
                  </template>
              </el-table-column>
              <el-table-column prop="card_bg_color" label="默认背景色">
                  <template slot-scope="scope">
                      <div class="dufauleColor" :style="{background: scope.row.template_attr_struct.card_bg_color}"></div>
                  </template>
              </el-table-column>
              <el-table-column prop="updated_time" label="创建时间">
                <template slot-scope="scope">
                    {{ handleTime(scope.row.created_time) }}
                </template>
              </el-table-column>
              <el-table-column prop="name" label="操作">
                  <template slot-scope="scope">
                    <el-button v-role="'TempEdi'" type="text" size="small" @click="handlAdd(scope.row)">编辑</el-button>
                    <el-button v-role="'TempDel'" type="text" style="color: #f13e40" @click="deleteUser(scope.row)" size="small">删除</el-button>
                  </template>
              </el-table-column>
          </template>
      </VTable>
    </div>
</template>

<script>
  import { timestampToTime } from '../utils/timeset'
  import VTable from '../components/form/VTable.vue'
  import * as Template from '../api/template'
  import * as Bank from '../api/bank'
  import * as Public from '@/api/public'
  export default {
    data() {
      return {
        loading: false,
        user_id: '',
        searchForm: {
          template_name: '',
          bank_id: ''
        },
        current_page: 1,
        tableData: [],
        pagination:{},
        bankList: [],
        ownerBankList: [],
      }
    },
    components: {
        VTable
    },
    created() {
      this.getList()
      this.getBankList()
      this.getownerBankList()
    },
    methods: {
        //获取管理员列表
        getList(params) {
          let obj = {}
          if(params) {
            obj = Object.assign(params,{current_page: this.current_page,page_size: 10},this.searchForm)
          } else {
            obj = {
              ...this.searchForm,
              current_page: this.current_page,
              page_size: 10
            }
          }
          Template.Template(obj).then( res => {
            if( res.list && res.list ) {
              this.tableData = res.list
              this.pagination = res.pagination
              this.loading = false
            }
          })
        },
        //选择银行
        getBankList(){
            Bank.Bank({bank_level: 1}).then(res => {
                if(res.list) {
                    this.bankList = res.list
                }
            })
        },
        getownerBankList() {
          Public.ownerBank({current_page: 1,page_size: 999}).then(res => {
                if(res.list) {
                    this.ownerBankList = res.list
                }
            })
        },
        getBanName(id) {
          // debugger
          let name = ''
          if(this.bankList.length) {
            this.bankList.map(item => {
              if(item.bank_id == id) {
                name = item.bank_name
              }
            })
          }
          return name
        },
        //添加操作
        handlAdd(row) {
          if(row.template_id) {
            this.$router.push({path:'/TemplateDetail', query: {id: row.template_id}})
          } else {
            this.$router.push({path:'/TemplateDetail'})
          }
        },
        //提交搜索表单
        submiteSearch(formName) {
          this.$refs[formName].validate((valid) => {
            if ( valid ) {
              this.getList()
            }
          })
        },
        //删除
        deleteUser(row) {
          this.loading = true
          let template_id = row.template_id
          Template.deleteTemplate(template_id).then(res => {
            this.loading = false
            this.getList()
          })
        },
        pageChange(value) {
            if(value) {
              this.current_page = value
            }
            this.getList()
        },
        //时间处理
        handleTime(time) {
          return timestampToTime(time)
        },
    },
  }
</script>

<style lang="scss" scoped>
  .dialogFooter{
    text-align: right;
  }
  .bindStatus{
    display: flex;
    align-items: center;
    .bindWxImg{
      // border-radius: 50%;
      width: 30px;
      height: 30px;
      margin-right: 10px;
    }
    .bankLogo{
      width: 100px;
    }
  }
  .dufauleColor{
    display: inline-block;
    width: 35px;
    height: 35px;
  }

</style>