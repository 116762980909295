/**
 * Create by Charyuan on 2018/08/13
*/

export const timesRange = []
for(var i = 0; i < 24; i++)timesRange.push(i>=10 ? `${i}` : `0${i}`)

export const weekHours = []
for (var i = 1; i <= 7; i++) {
    for(var j = 0;j < 24; j++) {
        weekHours.push(i * j)
    }
}
export const getWeekHour = function (weekArr, hourArr) {
    //weekArr : [1,..., 7]
    //hourArr : [{start: end: }] length <=3 , range 0~23
    var res = []
    if (Object.prototype.toString.call(hourArr) === '[object Array]' &&
        Object.prototype.toString.call(weekArr) === '[object Array]') {
        if (hourArr.length === 0 || weekArr.length === 0) { return }
        var hours = []
        hourArr.map(obj => {
            if (!obj) { return }
            var start = parseInt(obj.start) || 0
            var end = parseInt(obj.end) || 0
            for (var i = start; i < end; i++) {
                if (hours.indexOf(i) >= 0) { return }
                hours.push(i)
            }
        })
        hours.sort()
        weekArr.map(val => {
            if (!val) { return }
            var value = parseInt(val) || 0
            hours.map(hour => {
                res.push(hour * value)
            })
        })
    }else {
        throw new Error()
    }

    return res

}

export const setWeekHour = function (weekHour, callback) {
    if (Object.prototype.toString.call(weekHour) === '[object Array]')  {
        var weekArr=[], hourArr=[]
        if (weekHour.length === 0) {
            hourArr = [{start: '', end: ''}]
        }else {

        }


        callback && callback(weekArr, hourArr)
    }else {
        throw new Error()
    }
}

export const timestampToTime = function(timestamp) {
    if(timestamp) {
        let date = new Date(timestamp * 1000); //时间戳为10位需*1000，时间戳为13位的话不需乘1000
        let Y = date.getFullYear() + '-';
        let M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
        let D = date.getDate() < 10 ? '0' + date.getDate() + ' ' : date.getDate() + ' ';
        let h = date.getHours() + ':';
        let m = date.getMinutes() + ':';
        let s = date.getSeconds();
        return Y + M + D + h + m + s
    }
}